import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.REACT_APP_API,
})

axios.interceptors.request.use(config => {
    let token = localStorage.getItem('token')
    if(token){
        config.headers = {
            'Authorization': 'Bearer ' + token,
        }
    }
    return config
}, error => {
    console.log(error.response.data)
})

axios.interceptors.response.use(response => {
    return response
}, error => {
    if(error.response.status === 401){
        localStorage.clear()
        window.location.reload()
    }else{
        console.log(error.response.data)
    }
})

export { axios }