import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import iconMail from '../images/mail.svg'
import iconPending from '../images/pending.svg'
import iconCheck from '../images/check-circle.svg'

import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function Timeline({ events }) {

    const { eventTypes, machines, customers } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const TimelineItem = ({ date, event }) => {

        const machine = machines.find(m => parseInt(m.id) === parseInt(event.machine_id))
        const [customer, setCustomer] = useState()

        useEffect(() => {
            if(machine){
                setCustomer(customers.find(c => c.id === parseInt(machine.customer_id)))
            }
        }, [machine])
        
        return (            
        <div key={event.id} className="box">
            <div className="timelineEventType">{eventTypes.find(type => parseInt(type.id) === parseInt(event.event_type_id)).name}</div>
            <Row>
                <Col lg={3}>{ t('machine') }:</Col>
                <Col>
                    {machine && <Link to={'/machines/' + machine.id}>{ machine.name }</Link> }
                </Col>
            </Row>
            <Row>
                <Col lg={3}>{ t('customer') }:</Col>
                <Col>
                    {customer && <Link to={'/customers/' + customer.id}>{ customer.company }</Link>}
                </Col>
            </Row>
            {event.remember &&
            <Row style={{marginTop: 2}}>
                {event.remember ? (
                    <EventReminder date={date} event={event} />
                ) : (
                    <Col>
                        <div className="notifyInfo grey">{ t('noReminder') }</div>
                    </Col>
                )}
            </Row>
            }
        </div>
        )
    }

    const EventReminder = ({ date, event }) => {

        let rememberDate = moment(date).subtract(event.remember, 'days')
        let eventType = eventTypes.find(type => parseInt(type.id) === parseInt(event.event_type_id))
        let confirmation = event.confirmations ? event.confirmations.find(c => moment(c.date).isSame(date)) : null

        return (
            rememberDate.isSameOrBefore(moment(), 'day') ? (
                <Col>
                    { rememberDate.isSame(moment(), 'day') && moment().hours() < 12 ? (
                        <div className="notifyInfo">
                            <img src={iconMail} />
                            <div className="green">{ t('reminderTodayAt12') }</div>
                        </div>
                    ):(
                        <div>
                            <div className="notifyInfo">
                                <img src={iconMail} />                            
                                <div className="green">{ t('reminderSentAt') } {rememberDate.format(t('dateFormat'))}</div>
                            </div>

                            { eventType.confirmation_required && confirmation && confirmation.status == 0 &&
                            <div className="notifyInfo confirmation pending">
                                <img src={iconPending} />
                                <div>{ t('confirmationPending') }</div>
                            </div>
                            }

                            { eventType.confirmation_required && confirmation && confirmation.status == 1 &&
                            <div className="notifyInfo confirmation confirmed">
                                <img src={iconCheck} />
                                <div style={{fontWeight: 700}}>{ t('eventConfirmed') }</div>
                            </div>
                            }
                        </div>
                    )}
                </Col>
            ):(
                <Col>
                    <div className="notifyInfo">
                        <img src={iconMail} style={{filter: 'grayscale(1)'}} />
                        <div className="grey">{ t('reminderAt') } {rememberDate.format(t('dateFormat'))}</div>
                    </div>
                </Col>
            )
        )
    }


    return (
    <div className="timeline">
        <Row>
            <Col lg={2}></Col>
            <Col lg={10} className="timelineBoxContainer"></Col>
        </Row>
        {Object.keys(events).map(date => (
        <Row key={date}>
            <Col lg={2} className="timelineDateContainer">
                <div className={moment(date).isSame(moment(), 'day') ? 'timelineDate today' : 'timelineDate'}>{moment(date).format(t('dateFormat'))}</div>
            </Col>
            <Col lg={10} className="timelineBoxContainer">

                <div style={{marginLeft: 30}}>
                <Row>
                    {events[date].map((event, index) => (
                    <Col key={'eventItem' + index} lg={6}>
                        <TimelineItem date={date} event={event} />
                    </Col>
                    ))}
                </Row>
                </div>

            </Col>
        </Row>
        ))}
    </div>
    )
}

export default Timeline