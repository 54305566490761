import React, { useContext } from 'react'
import { Switch, Route, NavLink, useRouteMatch } from 'react-router-dom'
import { Button, ButtonGroup } from 'react-bootstrap'

import { LocalizationContext } from '../scripts/LocalizationContext'

import PageHeader from '../components/PageHeader'
import LanguageSelector from '../components/LanguageSelector'
import SettingsUserScreen from './settings/Users'
import SettingsEventScreen from './settings/Events'
import SettingsFieldScreen from './settings/Fields'
import SettingsEmailsScreen from './settings/Emails'
import SettingsAppScreen from './settings/App'

function SettingsScreen() {

    const route = useRouteMatch()
    const { t } = useContext(LocalizationContext)

    return (
    <div>
        
        <PageHeader title={ t('menuSettings') } />

        <div id="pageActions">
            <div className="container">
                <div>
                    <ButtonGroup>
                        <NavLink exact to={route.url} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuSettingsUsers') }</Button>
                        </NavLink>
                        <NavLink to={route.url + '/events'} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuSettingsEvents') }</Button>
                        </NavLink>
                        <NavLink to={route.url + '/fields'} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuSettingsFields') }</Button>
                        </NavLink>
                        <NavLink to={route.url + '/emails'} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuSettingsEmails') }</Button>
                        </NavLink>
                        <NavLink to={route.url + '/app'} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuSettingsApp') }</Button>
                        </NavLink>
                    </ButtonGroup>
                    <LanguageSelector />
                </div>
            </div>
        </div>

        <div id="pageContent">
            <div className="container">

                <Switch>
                    <Route exact path={route.path} component={SettingsUserScreen} />
                    <Route path={route.path + '/events'} component={SettingsEventScreen} />
                    <Route path={route.path + '/fields'} component={SettingsFieldScreen} />
                    <Route path={route.path + '/emails'} component={SettingsEmailsScreen} />
                    <Route path={route.path + '/app'} component={SettingsAppScreen} />
                </Switch>

            </div>
        </div>

    </div>
    )
}

export default SettingsScreen