import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import iconLoading from '../../images/loader-button.svg'

import Window from '../../components/Window'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { axios } from '../../scripts/Axios'

function SettingsEmailsScreen() {

    const { reminderMail, setReminderMail, confirmationMail, setConfirmationMail, language, isDefaultLanguage } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [reminderMailChanged, setReminderMailChanged] = useState(false)
    const [reminderMailEditor, setReminderMailEditor] = useState(null)
    const [reminderMailEditorData, setReminderMailEditorData] = useState(reminderMail.html)
    const [tempReminderMail, setTempReminderMail] = useState(reminderMail)
    const [savingReminderMail, setSavingReminderMail] = useState(false)

    const [confirmationMailChanged, setConfirmationMailChanged] = useState(false)
    const [confirmationMailEditor, setConfirmationMailEditor] = useState(null)
    const [confirmationMailEditorData, setConfirmationMailEditorData] = useState(confirmationMail.html)
    const [tempConfirmationMail, setTempConfirmationMail] = useState(confirmationMail)
    const [savingConfirmationMail, setSavingConfirmationMail] = useState(false)


    useEffect(() => {
        if(reminderMailEditorData && tempReminderMail){
            setTempReminderMail(isDefaultLanguage ? {
                ...tempReminderMail,
                value: {
                    ...tempReminderMail.value,
                    html: reminderMailEditorData,
                }
            } : {
                ...tempReminderMail,
                translations: tempReminderMail.translations ? {
                    ...tempReminderMail.translations,
                    [language]: {
                        ...tempReminderMail.translations[language],
                        html: reminderMailEditorData,
                    },
                } : {
                    [language]: { html: reminderMailEditorData, subject: '' }
                }
            })
        }
    }, [reminderMailEditorData])

    useEffect(() => {
        if(confirmationMailEditorData && tempConfirmationMail){
            setTempConfirmationMail(isDefaultLanguage ? {
                ...tempConfirmationMail,
                value: {
                    ...tempConfirmationMail.value,
                    html: confirmationMailEditorData,
                }
            } : {
                ...tempConfirmationMail,
                translations: tempConfirmationMail.translations ? {
                    ...tempConfirmationMail.translations,
                    [language]: {
                        ...tempConfirmationMail.translations[language],
                        html: confirmationMailEditorData,
                    },
                } : {
                    [language]: { html: confirmationMailEditorData, subject: '' }
                }
            })
        }
    }, [confirmationMailEditorData])

    useEffect(() => {

        if(!tempReminderMail)
            setReminderMailEditorData('')
        else if(isDefaultLanguage)
            setReminderMailEditorData(tempReminderMail.value.html)
        else if(tempReminderMail.translations && language in tempReminderMail.translations)
            setReminderMailEditorData(tempReminderMail.translations[language].html)
        else
            setReminderMailEditorData('')

        if(!tempConfirmationMail)
            setConfirmationMailEditorData('')
        else if(isDefaultLanguage)
            setConfirmationMailEditorData(tempConfirmationMail.value.html)
        else if(tempConfirmationMail.translations && language in tempConfirmationMail.translations)
            setConfirmationMailEditorData(tempConfirmationMail.translations[language].html)
        else
            setConfirmationMailEditorData('')

    }, [language])

    function changeReminderMailSubject(e)
    {
        setTempReminderMail(isDefaultLanguage ? {
            ...tempReminderMail,
            value: {
                ...tempReminderMail.value,
                subject: e.target.value,
            }
        } : {
            ...tempReminderMail,
            translations: tempReminderMail.translations ? {
                ...tempReminderMail.translations,
                [language]: {
                    ...tempReminderMail.translations[language],
                    subject: e.target.value,
                },
            } : {
                [language]: { html: '', subject: e.target.value }
            }
        })
        setReminderMailChanged(true)
    }
    function changeReminderMailText()
    {
        setReminderMailEditorData(reminderMailEditor.getData())
        setReminderMailChanged(true)
    }

    function changeConfirmationMailSubject(e)
    {
        setTempConfirmationMail(isDefaultLanguage ? {
            ...tempConfirmationMail,
            value: {
                ...tempConfirmationMail.value,
                subject: e.target.value,
            }
        } : {
            ...tempConfirmationMail,
            translations: tempConfirmationMail.translations ? {
                ...tempConfirmationMail.translations,
                [language]: {
                    ...tempConfirmationMail.translations[language],
                    subject: e.target.value,
                },
            } : {
                [language]: { html: '', subject: e.target.value }
            }
        })
        setConfirmationMailChanged(true)
    }
    function changeConfirmationMailText()
    {
        setConfirmationMailEditorData(confirmationMailEditor.getData())
        setConfirmationMailChanged(true)
    }

    async function saveReminderMail(){
        setSavingReminderMail(true)
        let response = await axios.put('mail/eventReminderMail', {
            data: tempReminderMail,
        })
        if(response.status === 200){
            setReminderMail(response.data)
            setReminderMailChanged(false)
            setSavingReminderMail(false)
        }
    }

    async function saveConfirmationMail(){
        setSavingConfirmationMail(true)
        let response = await axios.put('mail/eventConfirmationMail', {
            data: tempConfirmationMail,
        })
        if(response.status === 200){
            setConfirmationMail(response.data)
            setConfirmationMailChanged(false)
            setSavingConfirmationMail(false)
        }
    }

    function getMailValue(mail, field){
        if(isDefaultLanguage){
            return mail.value ? mail.value[field] : ''
        }else{
            if(mail.translations){
                return language in mail.translations ? mail.translations[language][field] : mail.value[field]
            }
            else return ''
        }
    }

    return (
    <div className="box">

        <Prompt when={reminderMailChanged || confirmationMailChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col>

                <Window title={ t('eventReminder') } actions={() => (
                    <div className="windowActions">
                        <Button variant="success" disabled={!reminderMailChanged} onClick={saveReminderMail}>
                            { savingReminderMail ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>
                    
                    <Row>
                        <Col lg={10}>
                            <Form.Control value={ getMailValue(tempReminderMail, 'subject') } placeholder={t('subject')} onChange={changeReminderMailSubject} style={{marginBottom: 10}} />
                            <CKEditor
                                config={{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] }}
                                editor={ClassicEditor}
                                data={ getMailValue(tempReminderMail, 'html') }
                                onReady={editor => setReminderMailEditor(editor)}
                                onChange={changeReminderMailText}
                            />
                        </Col>
                        <Col className="mailVariables">
                            <label>{ t('variables') }:</label>
                            <input value="{$customerName}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$machineName}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$remainingDays}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$eventName}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$eventDate}" readOnly onFocus={e => e.target.select()} />
                        </Col>
                    </Row>

                </Window>

                <Window title={ t('eventConfirmation') } actions={() => (
                    <div className="windowActions">
                        <Button variant="success" disabled={!confirmationMailChanged} onClick={saveConfirmationMail}>
                            { savingConfirmationMail ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>
                    
                    <Row>
                        <Col lg={10}>
                            <Form.Control value={ getMailValue(tempConfirmationMail, 'subject') } placeholder={t('subject')} onChange={changeConfirmationMailSubject} style={{marginBottom: 10}} />
                            <CKEditor
                                config={{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] }}
                                editor={ClassicEditor}
                                data={ getMailValue(tempConfirmationMail, 'html') }
                                onReady={editor => setConfirmationMailEditor(editor)}
                                onChange={changeConfirmationMailText}
                            />
                        </Col>
                        <Col className="mailVariables">
                            <label>{ t('variables') }:</label>
                            <input value="{$confirmationUrl}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$customerName}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$machineName}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$remainingDays}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$eventName}" readOnly onFocus={e => e.target.select()} />
                            <input value="{$eventDate}" readOnly onFocus={e => e.target.select()} />
                        </Col>
                    </Row>

                </Window>

            </Col>
        </Row>

    </div>
    )
}

export default SettingsEmailsScreen