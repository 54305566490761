import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

//import MachineListItem from './MachineListItem'

function Table({ data = [], headers = [], routeBase }){

    const { customers } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [sortedData, setSortedData] = useState(data)
    const [sorting, setSorting] = useState({
        sortby: null,
        order: 0,
    })

    function TableValue(type, value){
        switch(type){
            case 'text':
                return value
            case 'bool':
                return value ? t('yes') : t('no')
            case 'status':
                return <div className={parseInt(value) === 1 ? 'statusCircle active' : 'statusCircle inactive'}></div>
            case 'customer':
                let customer = customers.find(c => parseInt(value) === c.id)    
                return customer ? customer.company : <div className="lightgrey">{ t('notAssigned') }</div>
            default:
                return value
        }
    }

    function SortTable(key) {
        sortedData.sort(SortFunction(key))
    }

    function SortFunction(newSortBy){
        let newOrder = sorting.order === 0 || sorting.order === -1 ? 1 : -1
        if(sorting.sortby !== newSortBy) newOrder = 1

        setSorting({
            sortby: newSortBy,
            order: newOrder,
        })

        return function(a, b){
            let aVar = a[newSortBy] ? a[newSortBy] : ''
            let bVar = b[newSortBy] ? b[newSortBy] : ''
            let result = (aVar < bVar) ? -1 : (aVar > bVar) ? 1 : 0
            return result * newOrder
        }
    }

    return (    
    <div className="table">
        <Row className="table-head">
            { headers.map((header, index) => {
                let sortClass = 'sortIcon'
                if(sorting.sortby === header.field){
                    if(sorting.order > 0) sortClass += ' sortUp'
                    if(sorting.order < 0) sortClass += ' sortDown'
                }
                return (
                <Col key={'tableHead'+index} style={header.width ? { flexBasis: header.width, flexGrow: 0 } : null}>
                    <span onClick={e => SortTable(header.field)}>
                        { header.label }
                        { header.sortable ? <div className={sortClass}></div> : null }
                    </span>
                </Col>
                )
            })}
        </Row>
        <div className="table-content">
            { sortedData && sortedData.map((item, index) => (                
                <Link key={'tableRow'+index} to={routeBase + item.id} className="listItem">
                    <Row>
                        { headers.map((header, index2) => (
                            <Col key={'tableCol'+index2} style={header.width ? { flexBasis: header.width, flexGrow: 0 } : null}>
                                <div style={ header.fontWeight ? { fontWeight: header.fontWeight } : null}>{ TableValue(header.type, item[header.field]) }</div>
                            </Col>
                        )) }
                    </Row>
                </Link>
            )) }
        </div>
    </div>
    )
}

export default Table