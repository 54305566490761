import React, { useEffect, useRef, useState, useContext } from 'react'
import { useParams, useHistory, useRouteMatch, Prompt, Link } from 'react-router-dom'
import { Row, Col, Button, Form, ButtonGroup, InputGroup, ToggleButton } from 'react-bootstrap'
import Placeholder from '../../images/placeholder.jpg'
import iconLoading from '../../images/loader-button.svg'

import { axios } from '../../scripts/Axios'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import Window from '../../components/Window'
import PrintQrButton from './PrintQr'

function MachineGeneralScreen() {

    const { id } = useParams()
    const history = useHistory()
    const route = useRouteMatch()
    
    const { machines, setMachines, machineFields, customers, loadImage, checkFields, language, isDefaultLanguage, appSettings } = useContext(Context)
    const { t, locale } = useContext(LocalizationContext)

    const [machine, setMachine] = useState()
    const [customer, setCustomer] = useState()
    const [qrUrl, setQrUrl] = useState('')
    const [qrCode, setQrCode] = useState('')

    const [machineDataChanged, setMachineDataChanged] = useState(false)
    const [savingMachineData, setSavingMachineData] = useState(false)
    const [changedImage, setChangedImage] = useState(null)

    const [editLocation, setEditLocation] = useState(false)
    const [orgLocation, setOrgLocation] = useState('')

    const [qrButtonText, setQrButtonText] = useState(t('copy'))
    const refFileInput = useRef()
    const QR_API = 'https://quickchart.io/qr?margin=2&size=500&text='


    useEffect(() => {
        if(machines){
            if(changedImage !== null){
                setMachine({
                    ...machine,
                    image_cache: changedImage,
                })
                setChangedImage(null)
            }else{
                setMachine(machines.find(m => m.id === parseInt(id)))
            }
        }
    }, [machines])

    useEffect(() => {
        if(machine){
            setCustomer(customers.find(c => c.id === parseInt(machine.customer_id)))
        }
    }, [machine])

    useEffect(() => {
        if(customer){
            generateQR()
        }
    }, [customer])

    function generateQR(){
        let url = new URL(process.env.REACT_APP_CUSTOMER_URL)
        url.searchParams.append('m', machine.id)
        url.searchParams.append('s', machine.secret)
        setQrUrl(url.href)
        setQrCode(QR_API + encodeURIComponent(url.href))
    }

    async function changeImage(e){        
        if(e.target.files.length){
            
            let formData = new FormData()
            formData.append('image', e.target.files[0])

            let response = await axios.post('machine/' + machine.id + '/image', formData)
            if(response.status === 200){
                updateMachineImage(machine, response.data.image_uri)
            }

        }
    }

    async function updateMachineImage(machine, uri){
        let img = await loadImage(uri)
        setChangedImage(img)
        setMachines(machines.map(m => {
            return m.id === parseInt(machine.id) ? {
                ...m,
                image_cache: img,
            } : m
        }))
    }

    function changeMachineData(field, value){
        setMachine({
            ...machine,
            [field]: value,
        })
        setMachineDataChanged(true)
    }

    function changeMachineDynamicData(fieldID, value){

        let found = false
        let newFields = []
        if(machine.dynamicFields){
            newFields = machine.dynamicFields.map(f => {
                if(f.field_id == fieldID){
                    found = true
                    if(isDefaultLanguage)
                        f.field_value = value
                    else
                        f.translations = {
                            ...f.translations,
                            [language]: value,
                        }
                }
                return f
            })
        }
        if(!found){
            newFields.push({
                machine_id: machine.id,
                field_id: fieldID,
                field_value: value,
                translations: isDefaultLanguage ? [] : { [language]: value },
            })
        }

        setMachine({
            ...machine,
            dynamicFields: newFields,
        })
        setMachineDataChanged(true)
    }

    async function saveMachineData(){
        let check = checkFields([machine], ['name'])
        if(!check){ return }
        setSavingMachineData(true)
        let response = await axios.put('machine/' + machine.id, machine)
        if(response.status === 200){
            setMachines(machines.map(m => {
                return m.id === parseInt(machine.id) ? machine : m
            }))
            setSavingMachineData(false)
            setMachineDataChanged(false)
            setEditLocation(false)
        }
    }

    function startLocationEdit(){
        setOrgLocation(machine.location)
        setEditLocation(true)
    }
    function cancelLocationEdit(){
        setMachine({
            ...machine,
            location: orgLocation
        })
        setEditLocation(false)
    }

    function copyQrUrl(){
        navigator.clipboard.writeText(qrUrl)
        setQrButtonText(t('copied'))
        setTimeout(() => setQrButtonText(t('copy')), 2000)
    }

    return (
    <div className="box">

        <Prompt when={machineDataChanged} message={ t('alertUnsavedChanges') } />

         <Row>
            <Col lg={4}>

                <Window title={ t('image') } actions={() => (
                    <div className="windowActions">
                        <Button variant="secondary" onClick={() => refFileInput.current.click()}>{ t('change') }</Button>
                        <input ref={refFileInput} type="file" style={{display: 'none'}} onChange={changeImage} />
                    </div>
                )} style={{marginBottom: 20}}>

                    <div className="windowImage">
                        <img src={(machine && machine.image_cache) || Placeholder} alt="image" />
                    </div>

                </Window>

                <Window title={ t('qrcode') } actions={() => (
                    <div className="windowActions">                    
                        {qrCode && <PrintQrButton qr={qrCode} logo={appSettings.appLogo} machine={machine} />}
                    </div>
                )} style={{marginBottom: 20}}>

                    <div className="windowImage">
                        {qrCode && <img src={qrCode} alt="icon" />}
                    </div>

                    {qrCode && 
                    <InputGroup className="mt-1">
                        <Form.Control value={qrUrl} readOnly />
                        <Button variant="outline-secondary" onClick={copyQrUrl}>{ qrButtonText }</Button>
                    </InputGroup>
                    }

                </Window>

            </Col>
            <Col lg={8}>

                <Window title={ t('machineData') } actions={() => (
                    <div className="windowActions">
                        <Button variant="success" disabled={!machineDataChanged} onClick={saveMachineData}>
                            { savingMachineData ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )} style={{marginBottom: 20}}>

                    {machine &&
                    <div className="inputList">
                        <Row>
                            <Form.Label column sm={4}>{ t('status') }</Form.Label>
                            <Col sm={8}>
                                <ButtonGroup toggle>
                                    <ToggleButton type="radio" variant="secondary" checked={parseInt(machine.status) === 1} onChange={(e) => changeMachineData('status', 1)}>{ t('active') }</ToggleButton>
                                    <ToggleButton type="radio" variant="secondary" checked={parseInt(machine.status) === 0} onChange={(e) => changeMachineData('status', 0)}>{ t('inactive') }</ToggleButton>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('name') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={machine.name || ''} onChange={e => changeMachineData('name', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('serialnr') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={machine.serial_nr || ''} onChange={e => changeMachineData('serial_nr', e.target.value)} />
                            </Col>
                        </Row>

                        <hr className="mb-3" />

                        {machineFields.map(field => {
                            return (
                                <Row key={field.id}>
                                    <Form.Label column sm={4}>{ locale in field.translations ? field.translations[locale] : field.name }</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type={field.type} value={ machine.dynamicFields && machine.dynamicFields.find(df => df.field_id == field.id) ? (language in machine.dynamicFields.find(df => df.field_id == field.id).translations ? machine.dynamicFields.find(df => df.field_id == field.id).translations[language] : (machine.dynamicFields.find(df => df.field_id == field.id) ? machine.dynamicFields.find(df => df.field_id == field.id).field_value : '') ) : '' } onChange={e => changeMachineDynamicData(field.id, e.target.value)} />
                                    </Col>
                                </Row>
                            )
                        })}


                    </div>
                    }

                </Window>
                

                <Window title={ t('location') + ' / ' + t('customer') } actions={() => (
                    <div className="windowActions">
                        <Button variant="secondary" onClick={() => history.push(route.url + '/transfer')}>{ t('transferMachine') }</Button>
                    </div>
                )}>

                    { !customer && <div className="grey">{ t('notAssigned') }</div>}
                    { customer &&
                    <Row>
                        <Col sm={5}>
                            <Row>
                                <Form.Label column sm={12}>{ t('location') }</Form.Label>
                                { !editLocation ? (
                                <Col sm={12} className="py-2" style={{fontSize: 16, whiteSpace: 'break-spaces'}}>
                                    <div>{machine.location}</div>
                                    <button type="button" className="btn btn-secondary mt-3" onClick={startLocationEdit}>{ t('edit') }</button>
                                </Col>
                                ):(
                                <Col sm={12}>
                                    <Form.Control as="textarea" rows={3} value={machine.location} onChange={e => changeMachineData('location', e.target.value)} />
                                    <div className="mt-2">
                                        <button type="button" className="btn btn-secondary mr-2" onClick={cancelLocationEdit}>{ t('cancel') }</button>
                                        <Button variant="success" onClick={saveMachineData}>
                                            { savingMachineData ? <img src={iconLoading} alt="icon" /> : t('save') }
                                        </Button>
                                    </div>
                                </Col>
                                )}
                            </Row>
                        </Col>
                        <Col sm={7}>
                            <Row>
                                <Form.Label column sm={4}>{ t('customerNr') }</Form.Label>
                                <Col sm={8} className="py-2" style={{fontSize: 16}}>{customer.customer_nr}</Col>
                            </Row>
                            <Row>
                                <Form.Label column sm={4}>{ t('company') }</Form.Label>
                                <Col sm={8} className="py-2" style={{fontSize: 16}}><Link to={'/customers/' + customer.id}>{ customer.company }</Link></Col>
                            </Row>
                            <Row>
                                <Form.Label column sm={4}>{ t('address') }</Form.Label>
                                <Col sm={8} className="py-2" style={{fontSize: 16, whiteSpace: 'break-spaces'}}>{customer.address}</Col>
                            </Row>
                            <Row>
                                <Form.Label column sm={4}>{ t('email') }</Form.Label>
                                <Col sm={8} className="py-2" style={{fontSize: 16}}>{customer.email}</Col>
                            </Row>
                            <Row>
                                <Form.Label column sm={4}>{ t('mobile') }</Form.Label>
                                <Col sm={8} className="py-2" style={{fontSize: 16}}>{customer.mobile}</Col>
                            </Row>
                            <Row>
                                <Form.Label column sm={4}>{ t('phone') }</Form.Label>
                                <Col sm={8} className="py-2" style={{fontSize: 16}}>{customer.phone}</Col>
                            </Row>
                            <Row>
                                <Form.Label column sm={4}>{ t('fax') }</Form.Label>
                                <Col sm={8} className="py-2" style={{fontSize: 16}}>{customer.fax}</Col>
                            </Row>
                            <Row>
                                <Form.Label column sm={4}>{ t('website') }</Form.Label>
                                <Col sm={8} className="py-2" style={{fontSize: 16}}>{customer.website}</Col>
                            </Row>
                        </Col>
                    </Row>
                    }

                </Window>

            </Col>
        </Row>

    </div>
    )
}

export default MachineGeneralScreen