import React, { useEffect, useContext, useState } from 'react'
import moment from 'moment'

import { LocalizationContext } from '../scripts/LocalizationContext'

function PageHeader({ title }) {

    const { t } = useContext(LocalizationContext)

    const [time, setTime] = useState({
        date: moment().format(t('dateFormat')),
        time: moment().format('HH:mm'),
    })

    useEffect(() => {
        updateTime()
        let timer = setInterval(updateTime, 1000)
        return () => clearInterval(timer)
    }, [])

    function updateTime(){
        setTime({
            date: moment().format(t('dateFormat')),
            time: moment().format('HH:mm'),
        })
    }

    return (        
        <div id="pageHeader">
            <div className="container">
                <div className="pageTitle">{ title }</div>
                <div className="pageTime">{time.date} <strong>{time.time}</strong></div>
            </div>
        </div>
    )
}

export default PageHeader