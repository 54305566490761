export const en = {
    // Languages
    'language': 'Language',
    'german': 'German',
    'english': 'English',

    // Date Format
    'dateFormat': 'MM/DD/YYYY',

    // Main Menu
    'menuMachines': 'Machines',
    'menuDocumentation': 'Documentation',
    'menuCustomers': 'Customers',
    'menuEvents': 'Events',
    'menuMaintenance': 'Maintenance',
    'menuService': 'Service Page',
    'menuHistory': 'History',
    'menuSettings': 'Settings',
    'menuLogout': 'Logout',

    // Machine Menu
    'menuMachineGeneral': 'General',
    'menuMachineEvents': 'Events',
    'menuMachineDocumentation': 'Documentation',
    'menuMachineTutorials': 'Tutorials',
    'menuMachineUploads': 'Uploads',

    // Customer Menu
    'menuCustomerGeneral': 'General',
    'menuCustomerMachines': 'Machines',

    // Settings Menu
    'menuSettingsUsers': 'Users',
    'menuSettingsEvents': 'Events',
    'menuSettingsFields': 'Fields',
    'menuSettingsEmails': 'Emails',
    'menuSettingsApp': 'App',

    // General
    'logo': 'Logo',
    'search': 'Search',
    'save': 'Save',
    'password': 'Password',
    'firstAndLastname': 'First and last name',
    'email': 'eMail',
    'text': 'Text',
    'number': 'Number',
    'date': 'Date',
    'user': 'User',
    'allUsers': 'All users',
    'allCustomers': 'All customers',
    'allEvents': 'All events',
    'userDeleted': 'User deleted',
    'system': 'System',
    'activity': 'Activity',
    'showMore': 'Show more',
    'machine': 'Machine',
    'customer': 'Customer',
    'createMachine': 'Create machine',
    'createCustomer': 'Create customer',
    'machineCountLabel': 'Machine(s)',
    'subscription': 'Subscription',
    'activeSubscription': 'Active subscription',
    'noSubscription': 'No subscription',
    'active': 'Active',
    'inactive': 'Inactive',
    'login': 'Login',
    'loginTitle': 'Login',
    'name': 'Name',
    'change': 'Change',
    'edit': 'Edit',
    'cancel': 'Cancel',
    'status': 'Status',
    'print': 'Print',
    'image': 'Image',
    'qrcode': 'QR code',
    'location': 'Location',
    'back': 'Back',
    'from': 'from',
    'by': 'From',
    'folders': 'Folders',
    'files': 'Files',
    'activate': 'Activate',
    'deactivate': 'Deactivate',
    'yes': 'Yes',
    'no': 'No',
    'close': 'Close',
    'users': 'Users',
    'serialnr': 'Serial nr.',
    'copy': 'Copy',
    'copied': 'Copied',
    'loading': 'Loading',

    // User Roles
    'roleStaff': 'Staff',
    'roleAdmin': 'Administrator',

    // Alerts
    'alertUnsavedChanges': 'There are unsaved changes. Do you really want to leave this page and discard your changes?',
    'alertFillFields': 'Please fill out all required fields.',
    'alertDeleteCustomer': 'Do you really want to permanently delete this customer?',
    'alertDeleteMachine': 'Do you really want to permanently delete this machine including all associated files and information?',
    'alertWrongLogin': 'The login details are incorrect.',
    'alertPinNeeded': 'Please enter a PIN first.',
    
    // Machine Screen
    'notAssigned': 'Not assigned',
    'transferMachine': 'Transfer machine',
    'machineData': 'Machine data',
    'noEvents': 'There are no events',
    'chooseEventType': 'Choose event type',
    'noRepeat': 'No repetition',
    'dailyRepeat': 'Daily repetition',
    'weeklyRepeat': 'Weekly repetition',
    'monthlyRepeat': 'Monthly repetition',
    'yearlyRepeat': 'Yearly repetition',
    'unnamedFolder': 'Unnamed folder',
    'noFolders': 'There are no folders',
    'noFiles': 'There are no files',
    'noRecipients': 'There are no recipients',
    'noFtpFolder': 'No FTP folder created',
    'createFtpFolder': 'Create FTP folder',
    'fileUploads': 'File uploads',
    'uploadRecipients': 'Upload recipients',
    'downloadPdf': 'Download PDF',

    // Transfer
    'transferWarning': 'When transferring to another customer, a new QR code will be generated for that machine. As a result, the previous QR code will no longer work.',
    'transferMachine': 'Transfer machine',
    'transfer': 'Transfer',
    'chooseDestination': 'Choose destination',

    // Documentation Screen
    'globalFilesInfo': 'Following global files are visible in every machine. Please upload machine-specific files directly into the respective machine.',

    // Customer Screen
    'customerData': 'Customer data',
    'customerPin': 'Customer PIN',
    'customerNr': 'Customer nr.',
    'company': 'Company',
    'address': 'Address',
    'mobile': 'Mobile',
    'phone': 'Phone',
    'fax': 'Fax',
    'website': 'Website',
    
    // Events Screen
    'noReminder': 'No reminder',
    'reminderAt': 'Reminder on',
    'reminderSentAt': 'Reminder sent on',
    'reminderTodayAt12': 'Reminder today at 12:00',
    'confirmationPending': 'Confirmation pending',
    'eventConfirmed': 'Event confirmed',

    // Maintenance Screen
    'allMachines': 'All machines',
    'allCustomers': 'All customers',
    'maintenanceDetails': 'Maintenance details',
    'createdOn': 'Created on',
    'editedOn': 'Edited on',
    'maintenancedOn': 'Performed on',
    'maintenancedBy': 'Performed by',
    'maintenancePhone': 'Phone number for queries',
    'maintenanceDescription': 'Maintenance description',
    'maintenanceRemote': 'Remote maintenance',
    'remote': 'Remote',
    
    // Service Screen
    'serviceText': 'Service text',
    
    // Settings Screen
    'eventTypes': 'Event types',
    'eventName': 'Event name',
    'remindDaysBefore': 'days before remember',
    'showAlwaysInFrontend': 'Always show in frontend',
    'confirmationRequired': 'Confirmation required',
    'showInFrontend': 'Show in frontend',
    'machineFields' : 'Machine fields',
    'unnamedField': 'Unnamed field',
    'appSettings': 'App settings',
    'changeLogo': 'Change logo',
    'appLanguage': 'Backend language',
    'primaryAppColor': 'Primary app color',
    'secondaryAppColor': 'Secondary app color',
    'eventReminder': 'Event reminder',
    'eventConfirmation': 'Event confirmation',
    'subject': 'Subject',
    'variables': 'Variables',
}