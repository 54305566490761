import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, InputGroup, Button, Form } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import iconPlus from '../../images/plus.svg'
import iconDelete from '../../images/delete.svg'
import iconLoading from '../../images/loader-button.svg'

import Window from '../../components/Window'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { axios } from '../../scripts/Axios'

function SettingsFieldScreen() {

    const { machineFields, setMachineFields, checkFields, language, isDefaultLanguage } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [fields, setFields] = useState([])
    const [deleteFields, setDeleteFields] = useState([])
    const [fieldsChanged, setFieldsChanged] = useState(false)
    const [savingFields, setSavingFields] = useState(false)

    useEffect(() => {
        setFields(machineFields)
    }, [machineFields])

    function addField(){
        setFields([
            {
                name: t('unnamedField'),
                type: 'text',
                frontend: false,
                translations: [],
            },
            ...fields,
        ])
        setFieldsChanged(true)
    }

    function changeField(i, field, value)
    {
        let updatedFields = fields.map((f, index) => {
            if(i === index){
                if(isDefaultLanguage || field != 'name'){
                    return {
                        ...f,
                        [field]: value,
                    }
                }else{
                    return {
                        ...f,
                        translations: {
                            ...f.translations,
                            [language]: value,
                        }
                    }
                }
            }
            else return f
        })

        setFields(updatedFields)
        setFieldsChanged(true)
    }

    function deleteField(i){
        let del = fields.find((field, index) => index === i)
        if(del.id){
            setDeleteFields([...deleteFields, del])
        }        
        setFields(fields.filter((field, index) => index !== i))
        setFieldsChanged(true)
    }

    async function saveFields(){
        let check = checkFields(fields, ['name'])
        if(!check){ return }
        setSavingFields(true)
        let response = await axios.put('machine-fields', {
            updateFields: fields.filter(f => f.id),
            createFields: fields.filter(f => !f.id),
            deleteFields: deleteFields,
        })
        if(response.status === 200){
            setMachineFields(response.data)
            setDeleteFields([])
            setSavingFields(false)
            setFieldsChanged(false)
        }
    }


    return (
    <div className="box">

        <Prompt when={fieldsChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col>

                <Window title={ t('machineFields') } actions={() => (
                    <div className="windowActions">
                        <Button variant="secondary" className="iconButton" onClick={addField}><img src={iconPlus} alt="icon" /></Button>
                        <Button variant="success" disabled={!fieldsChanged} onClick={saveFields}>
                            { savingFields ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>
                    
                    <div className="itemList">
                        {fields.map((field, index) => (                                
                            <Row key={'eventType' + index}>
                                <Col>
                                    <Form.Control value={ isDefaultLanguage ? field.name : (language in field.translations ? field.translations[language] : field.name) } onChange={e => changeField(index, 'name', e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Control as="select" value={field.type} onChange={e => changeField(index, 'type', e.target.value)}>
                                        <option value={'text'}>{ t('text') }</option>
                                        <option value={'number'}>{ t('number') }</option>
                                        <option value={'date'}>{ t('date') }</option>
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <InputGroup.Text>
                                        <Form.Check id={'checkbox'+index} type="switch" label={ t('showInFrontend') } checked={field.frontend} onChange={e => changeField(index, 'frontend', e.target.checked)} style={{ display: 'flex', alignItems: 'center' }} />
                                    </InputGroup.Text>
                                </Col>
                                <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
                                    <div className="listButton" onClick={() => deleteField(index)}>
                                        <img src={iconDelete} alt="icon" />
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>

                </Window>

            </Col>
        </Row>

    </div>
    )
}

export default SettingsFieldScreen