import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import iconLoading from '../images/loader-button.svg'

import PageHeader from '../components/PageHeader'
import LanguageSelector from '../components/LanguageSelector'
import Window from '../components/Window'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { axios } from '../scripts/Axios'

function ServiceScreen() {

    const { serviceText, setServiceText, language, isDefaultLanguage } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [editor, setEditor] = useState(null)
    const [editorData, setEditorData] = useState(serviceText.value)
    const [tempServiceText, setTempServiceText] = useState(serviceText)

    const [serviceChanged, setServiceChanged] = useState(false)
    const [savingService, setSavingService] = useState(false)


    useEffect(() => {
        if(editorData && tempServiceText){
            setTempServiceText(isDefaultLanguage ? {
                ...tempServiceText,
                value: editorData,
            } : {
                ...tempServiceText,
                translations: {
                    ...tempServiceText.translations,
                    [language]: editorData,
                }
            })
        }
    }, [editorData])

    useEffect(() => {

        if(!tempServiceText)
            setEditorData('')
        else if(isDefaultLanguage)
            setEditorData(tempServiceText.value)
        else if(language in tempServiceText.translations)
            setEditorData(tempServiceText.translations[language])
        else
            setEditorData('')

    }, [language])

    function changeServiceText()
    {
        setEditorData(editor.getData())
        setServiceChanged(true)
    }

    async function saveServiceText(){
        setSavingService(true)
        let response = await axios.put('servicetext', {
            data: tempServiceText,
        })
        if(response.status === 200){
            setServiceText(response.data)
            setServiceChanged(false)
            setSavingService(false)
        }
    }


    return (
        <div>
            
            <PageHeader title={ t('menuService') } />
            
            <div id="pageActions">
                <div className="container">                    
                    <LanguageSelector offsetLeft={0} />
                </div>
            </div>
    
            <div id="pageContent">
                <div className="container">

                    <div className="box">

                        <Prompt when={serviceChanged} message={ t('alertUnsavedChanges') } />

                        <Row>
                            <Col>

                                <Window title={ t('serviceText') } actions={() => (
                                    <div className="windowActions">
                                        <Button variant="success" disabled={!serviceChanged} onClick={saveServiceText}>
                                            { savingService ? <img src={iconLoading} alt="icon" /> : t('save') }
                                        </Button>
                                    </div>
                                )}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={ isDefaultLanguage ? tempServiceText.value : (language in tempServiceText.translations ? tempServiceText.translations[language] : tempServiceText.value) }
                                        onReady={editor => setEditor(editor)}
                                        onChange={() => changeServiceText()}
                                    />

                                </Window>

                            </Col>
                        </Row>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default ServiceScreen