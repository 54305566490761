import React, { useState, useEffect, useContext } from 'react'
import { Switch, Route, NavLink, useRouteMatch, useParams, useHistory } from 'react-router-dom'
import { ButtonGroup, Button } from 'react-bootstrap'
import iconTrash from '../images/trash.svg'

import PageHeader from '../components/PageHeader'
import LanguageSelector from '../components/LanguageSelector'
import MachineGeneralScreen from '../screens/machine/General'
import MachineEventsScreen from '../screens/machine/Events'
import MachineDocumentationsScreen from '../screens/machine/Documentations'
import MachineTutorialsScreen from '../screens/machine/Tutorials'
import MachineUploadsScreen from '../screens/machine/Uploads'
import MachineTransferScreen from '../screens/machine/Transfer'

import { axios } from '../scripts/Axios'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function MachineScreen() {

    const { id } = useParams()
    const history = useHistory()
    const route = useRouteMatch()
    
    const { machines, setMachines } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [machine, setMachine] = useState()

    useEffect(() => {
        if(machines && id){
            setMachine(machines.find(m => m.id === parseInt(id)))
        }
    }, [machines, id])

    async function deleteMachine(){
        let q = window.confirm(t('alertDeleteMachine'))
        if(!q){ return }
        let response = await axios.delete('machine/' + machine.id)
        if(response.status === 200){
            let updatedMachines = machines.filter(m => m.id !== machine.id)
            setMachines(updatedMachines)
            history.push('/machines')
        }
    }

    return (
    <div>
        
        <PageHeader title={machine && machine.name} />
        
        <div id="pageActions">
            <div className="container">
                <div>
                    <ButtonGroup>
                        <NavLink exact to={route.url} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuMachineGeneral') }</Button>
                        </NavLink>
                        <NavLink to={route.url + '/events'} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuMachineEvents') }</Button>
                        </NavLink>
                        <NavLink to={route.url + '/documentation'} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuMachineDocumentation') }</Button>
                        </NavLink>
                        <NavLink to={route.url + '/tutorials'} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuMachineTutorials') }</Button>
                        </NavLink>
                        <NavLink to={route.url + '/uploads'} activeClassName="active">
                            <Button variant="primary" size="lg">{ t('menuMachineUploads') }</Button>
                        </NavLink>
                    </ButtonGroup>
                    <LanguageSelector />
                </div>
                <Button variant="primary" size="lg" className="iconButton" style={{marginLeft: 'auto'}} onClick={deleteMachine}><img src={iconTrash} alt="icon" /></Button>
            </div>
        </div>

        <div id="pageContent">
            <div className="container">

                <Switch>
                    <Route exact path={route.path}>
                        <MachineGeneralScreen />
                    </Route>
                    <Route path={route.path + '/events'}>
                        <MachineEventsScreen />
                    </Route>
                    <Route path={route.path + '/documentation'}>
                        <MachineDocumentationsScreen />
                    </Route>
                    <Route path={route.path + '/tutorials'}>
                        <MachineTutorialsScreen />
                    </Route>
                    <Route path={route.path + '/uploads'}>
                        <MachineUploadsScreen />
                    </Route>
                    <Route path={route.path + '/transfer'}>
                        <MachineTransferScreen />
                    </Route>
                </Switch>

            </div>
        </div>

    </div>
    )
}

export default MachineScreen