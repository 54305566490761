import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, InputGroup, Button, Form } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import iconPlus from '../../images/plus.svg'
import iconDelete from '../../images/delete.svg'
import iconLoading from '../../images/loader-button.svg'

import Window from '../../components/Window'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { axios } from '../../scripts/Axios'

function SettingsEventScreen() {

    const { eventTypes, setEventTypes, checkFields, language, isDefaultLanguage } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [types, setTypes] = useState([])
    const [deleteTypes, setDeleteTypes] = useState([])
    const [typesChanged, setTypesChanged] = useState(false)
    const [savingTypes, setSavingTypes] = useState(false)

    useEffect(() => {
        setTypes(eventTypes)
    }, [eventTypes])

    function addType(){
        setTypes([
            {
                name: '',
                remember: '',
                show_always: false,
                confirmation_required: false,
                translations: [],
            },
            ...types,
        ])
        setTypesChanged(true)
    }

    function changeType(i, field, value)
    {
        let updatedTypes = types.map((type, index) => {
            if(i === index){
                if(isDefaultLanguage || field != 'name'){
                    return {
                        ...type,
                        [field]: value,
                    }
                }else{
                    return {
                        ...type,
                        translations: {
                            ...type.translations,
                            [language]: value,
                        }
                    }
                }
            }
            else return type
        })

        setTypes(updatedTypes)
        setTypesChanged(true)
    }

    function deleteType(i){
        let del = types.find((type, index) => index === i)
        if(del.id){
            setDeleteTypes([...deleteTypes, del])
        }        
        setTypes(types.filter((type, index) => index !== i))
        setTypesChanged(true)
    }

    async function saveTypes(){
        let check = checkFields(types, ['name'])
        if(!check){ return }
        setSavingTypes(true)
        let response = await axios.put('event-types', {
            updateTypes: types.filter(type => type.id),
            createTypes: types.filter(type => !type.id),
            deleteTypes: deleteTypes,
        })
        if(response.status === 200){
            setEventTypes(response.data)
            setDeleteTypes([])
            setSavingTypes(false)
            setTypesChanged(false)
        }
    }


    return (
    <div className="box">

        <Prompt when={typesChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col>

                <Window title={ t('eventTypes') } actions={() => (
                    <div className="windowActions">
                        <Button variant="secondary" className="iconButton" onClick={addType}><img src={iconPlus} alt="icon" /></Button>
                        <Button variant="success" disabled={!typesChanged} onClick={saveTypes}>
                            { savingTypes ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>
                    
                    <div className="itemList">
                        {types.map((type, index) => (                                
                            <Row key={'eventType' + index}>
                                <Col>
                                    <Form.Control value={ isDefaultLanguage ? type.name : (language in type.translations ? type.translations[language] : type.name) } placeholder={ t('eventName') } onChange={e => changeType(index, 'name', e.target.value)} />
                                </Col>
                                <Col>                                        
                                    <InputGroup>
                                        <Form.Control type="number" value={type.remember || ''} step="1" min="0" onChange={e => changeType(index, 'remember', e.target.value)} />
                                        <InputGroup.Append>
                                            <InputGroup.Text>{ t('remindDaysBefore') }</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup.Text>
                                        <Form.Check id={'confirmation_checkbox'+index} type="switch" label={ t('confirmationRequired') } checked={type.remember ? type.confirmation_required : false} onChange={e => type.remember ? changeType(index, 'confirmation_required', e.target.checked) : null} style={{ display: 'flex', alignItems: 'center' }} />
                                    </InputGroup.Text>
                                </Col>
                                <Col>
                                    <InputGroup.Text>
                                        <Form.Check id={'show_checkbox'+index} type="switch" label={ t('showAlwaysInFrontend') } checked={type.show_always} onChange={e => changeType(index, 'show_always', e.target.checked)} style={{ display: 'flex', alignItems: 'center' }} />
                                    </InputGroup.Text>
                                </Col>
                                <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
                                    <div className="listButton" onClick={() => deleteType(index)}>
                                        <img src={iconDelete} alt="icon" />
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>

                </Window>

            </Col>
        </Row>

    </div>
    )
}

export default SettingsEventScreen