import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import MachineGridItem from '../../components/MachineGridItem'
import Table from '../../components/Table'

function CustomerMachinesScreen() {

    const { id } = useParams()
    const { machines, user } = useContext(Context)
    const { t } = useContext(LocalizationContext)


    function MachineListView(){
        switch(user.viewmode){
            case 'grid': return <GridView />
            case 'list': return <ListView />
            default: return  <GridView />
        }
    }

    function GridView(){
        return (
            <Row>
            {machines && machines.filter(m => parseInt(m.customer_id) === parseInt(id)).map((machine, index) => (
                <Col key={'machineItem' + index} lg={3}>
                    <MachineGridItem machine={machine} />
                </Col>
            ))}
            </Row>
        )
    }

    function ListView(){
        return (
            <div className="box">
                <Table data={machines.filter(m => parseInt(m.customer_id) === parseInt(id))} routeBase="/machines/" headers={[
                    {
                        field: 'status',
                        type: 'status',
                        sortable: false,
                        width: 40,
                    },
                    {
                        label: t('name'),
                        field: 'name',
                        type: 'text',
                        sortable: true,
                        fontWeight: 700,
                    },
                    {
                        label: t('serialnr'),
                        field: 'serial_nr',
                        type: 'text',
                        sortable: true,
                    },
                ]} />
            </div>
        )
    }

    return <MachineListView />
}

export default CustomerMachinesScreen