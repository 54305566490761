import { useContext, useEffect } from 'react'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import {ReactComponent as IconUsers} from './images/users.svg'
import {ReactComponent as IconMachine} from './images/machine.svg'
import {ReactComponent as IconDocumentation} from './images/documentation.svg'
import {ReactComponent as IconCalendar} from './images/calendar.svg'
import {ReactComponent as IconGear} from './images/gear.svg'
import {ReactComponent as IconMaintenance} from './images/maintenance.svg'
import {ReactComponent as IconInfo} from './images/info.svg'
import {ReactComponent as IconHistory} from './images/history.svg'
import {ReactComponent as IconLogout} from './images/logout.svg'

import Loader from './components/Loader'

import LoginScreen from './screens/Login'
import MachineListScreen from './screens/MachineList'
import MachineScreen from './screens/Machine'
import CustomerListScreen from './screens/CustomerList'
import CustomerScreen from './screens/Customer'
import DocumentationScreen from './screens/Documentation'
import EventsScreen from './screens/Events'
import MaintenanceScreen from './screens/Maintenance'
import ServiceScreen from './screens/Service'
import HistoryScreen from './screens/History'
import SettingsScreen from './screens/Settings'

import { Context } from './scripts/Context'
import { LocalizationContext } from './scripts/LocalizationContext'

function App() {

    const { loading, user, logout, appSettings, changeLanguage } = useContext(Context)
    const { t, setLocale } = useContext(LocalizationContext)

    useEffect(() => {
        if(appSettings){
            setLocale(appSettings.language ? appSettings.language : 'de')
            changeLanguage(appSettings.language ? appSettings.language : 'de')
            document.title = appSettings.companyName
            document.documentElement.style.setProperty('--color-primary', appSettings.appColorPrimary)
            document.documentElement.style.setProperty('--color-primary-trans', appSettings.appColorPrimary + '25')
            document.documentElement.style.setProperty('--color-primary-dark', appSettings.appColorPrimaryDark)
        }
    }, [appSettings])

    return (
        <div id="wrapper">
            
            { user &&
            <div id="sidebar">
                <a href="/machines"><img src={appSettings && appSettings.appLogo} id="logo" alt="logo" /></a>
                <div id="menu">
                    <NavLink to="/machines" className="menuItem" activeClassName="active">
                        <div className="menuIcon">
                            <IconMachine fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuMachines') }</div>
                    </NavLink>
                    <NavLink to="/documentation" className="menuItem" activeClassName="active">
                        <div className="menuIcon">
                            <IconDocumentation fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuDocumentation') }</div>
                    </NavLink>
                    <NavLink to="/customers" className="menuItem" activeClassName="active">
                        <div className="menuIcon">
                            <IconUsers fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuCustomers') }</div>
                    </NavLink>
                    <NavLink to="/events" className="menuItem" activeClassName="active">
                        <div className="menuIcon">
                            <IconCalendar fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuEvents') }</div>
                    </NavLink>
                    <NavLink to="/maintenance" className="menuItem" activeClassName="active">
                        <div className="menuIcon">
                            <IconMaintenance fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuMaintenance') }</div>
                    </NavLink>
                    {user.role === 'admin' &&
                    <NavLink to="/service" className="menuItem" activeClassName="active">
                        <div className="menuIcon">
                            <IconInfo fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuService') }</div>
                    </NavLink>
                    }
                    <NavLink to="/history" className="menuItem" activeClassName="active">
                        <div className="menuIcon">
                            <IconHistory fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuHistory') }</div>
                    </NavLink>
                    {user.role === 'admin' &&
                    <NavLink to="/settings" className="menuItem" activeClassName="active">
                        <div className="menuIcon">
                            <IconGear fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuSettings') }</div>
                    </NavLink>
                    }
                    <div className="menuItem logoutButton" onClick={logout}>
                        <div className="menuIcon">
                            <IconLogout fill={appSettings && appSettings.appColorPrimary} />
                        </div>
                        <div className="menuTitle">{ t('menuLogout') }</div>
                    </div>
                </div>
            </div>
            }

            {loading ? <Loader /> : (
                <div id="page">
                {user ? (
                    <Switch>
                        <Route exact path="/"><Redirect to="/machines" /></Route>
                        <Route exact path="/machines" component={MachineListScreen} />
                        <Route path="/machines/:id" component={MachineScreen} />
                        <Route path="/documentation" component={DocumentationScreen} />
                        <Route exact path="/customers" component={CustomerListScreen} />
                        <Route path="/customers/:id" component={CustomerScreen} />
                        <Route path="/events" component={EventsScreen} />
                        <Route path="/maintenance" component={MaintenanceScreen} />
                        <Route path="/service" component={ServiceScreen} />
                        <Route path="/history" component={HistoryScreen} />
                        {user.role === 'admin' && <Route path="/settings" component={SettingsScreen} />}
                    </Switch>
                ) : (
                    <Switch>
                        <Route path="/" component={LoginScreen} />
                    </Switch>
                )}
                </div>
            )}

        </div>
    )
}

export default App