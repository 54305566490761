import React, { useState, useEffect, useContext } from 'react'
import { Form, Row, Col, Table, Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { axios } from '../scripts/Axios'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import PageHeader from '../components/PageHeader'
import Loader from '../components/Loader'

function MaintenanceScreen() {

    const { machines, customers } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [loading, setLoading] = useState(false)
    const [maintenance, setMaintenance] = useState([])
    const [filteredMaintenance, setFilteredMaintenance] = useState([])
    const [maintenanceModal, setMaintenanceModal] = useState(false)
    const [currentMaintenance, setCurrentMaintenance] = useState(null)

    const [filter, setFilter] = useState({
        machine: '',
        customer: '',
    })

    useEffect(() => {
        setLoading(true)
        getMaintenance()
    }, [])

    async function getMaintenance(){        
        let response = await axios.get('maintenance')
        if(response.status === 200){
            setMaintenance(response.data)
            setLoading(false)
        }
    }

    useEffect(() => {
        filterMaintenance()
    }, [filter, maintenance])

    function filterMaintenance(){
        if(filter.machine || filter.customer){
            let filteredMaintenance = maintenance
            if(filter.machine){
                filteredMaintenance = filteredMaintenance.filter(m => parseInt(m.machine_id) === parseInt(filter.machine))
            }
            if(filter.customer){
                filteredMaintenance = filteredMaintenance.filter(m => {
                    //let machine = machines.find(m => parseInt(m.id) === parseInt(h.machine_id))
                    return parseInt(m.customer_id) === parseInt(filter.customer) ? true : false
                })
            }
            setFilteredMaintenance(filteredMaintenance)
        }else{
            setFilteredMaintenance(maintenance)
        }
    }

    function showMaintenanceDetails(m){
        setCurrentMaintenance(m)
        setMaintenanceModal(true)
    }

    return (
    <div>
        
        <PageHeader title={ t('menuMaintenance') } />
        
        <div id="pageActions">
            <div className="container">
                <Form.Control as="select" size="lg" style={{maxWidth: 300, marginRight: 15}} onChange={e => setFilter({...filter, machine: e.target.value})}>
                    <option value="">{ t('allMachines') }</option>
                    {machines.map((machine, index) => <option key={'machineOption' + index} value={machine.id}>{machine.name}</option>)}
                </Form.Control>
                <Form.Control as="select" size="lg" style={{maxWidth: 300}} onChange={e => setFilter({...filter, customer: e.target.value})}>
                    <option value="">{ t('allCustomers') }</option>
                    {customers.map((customer, index) => <option key={'customerOption' + index} value={customer.id}>{customer.company}</option>)}
                </Form.Control>
            </div>
        </div>

        <div id="pageContent">
            <div className="container">

                {loading && <Loader />}

                <div className="box">
                    <Table responsive className="historyTable">
                        <thead>
                            <tr>
                                <th style={{minWidth:140}}>{ t('date') }</th>
                                <th>{ t('machine') }</th>
                                <th>{ t('customer') }</th>
                                <th>{ t('maintenancedBy') }</th>
                                <th>{ t('remote') }</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        { filteredMaintenance && filteredMaintenance.map((h, index) => {
                            let machine = machines.find(m => m.id === parseInt(h.machine_id))
                            let customer = customers.find(c => c.id === parseInt(h.customer_id))
                            if(!machine || !customer) return null
                            return (
                            <tr key={'maintenanceItem' + index}>
                                <td className="grey" width={180} style={{verticalAlign: 'middle'}}>{moment(h.created_at).format(t('dateFormat') + ' HH:mm')}</td>
                                <td style={{verticalAlign: 'middle'}}><Link to={'/machines/' + machine.id}>{ machine.name }</Link></td>
                                <td style={{verticalAlign: 'middle'}}><Link to={'/customers/' + customer.id}>{ customer.company }</Link></td>
                                <td style={{verticalAlign: 'middle'}}>{ h.performed_by }</td>
                                <td style={{verticalAlign: 'middle'}}>{ h.remote ? t('yes') : t('no') }</td>
                                <td width={170} style={{verticalAlign: 'middle'}}>
                                    <Button variant="secondary" onClick={() => showMaintenanceDetails(h)}>{ t('showMore') }</Button>
                                </td>
                            </tr>
                            )
                        }) }
                        </tbody>
                    </Table>
                </div>

            </div>
        </div>

        <Modal
            show={maintenanceModal}
            centered
            backdrop="static"            
            onHide={() => setMaintenanceModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>{ t('maintenanceDetails') }</Modal.Title>
            </Modal.Header>

            <Modal.Body className="mb-3">
                <Row>
                    <Form.Label column>{ t('machine') }</Form.Label>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>{ currentMaintenance ? (<Link to={'/machines/' + currentMaintenance.machine_id}>{ machines.find(m => m.id == currentMaintenance.machine_id).name }</Link>) : null }</Col>
                </Row>
                <Row>
                    <Form.Label column>{ t('customer') }</Form.Label>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>{ currentMaintenance ? (<Link to={'/customers/' + currentMaintenance.customer_id}>{ customers.find(m => m.id == currentMaintenance.customer_id).company }</Link>) : null }</Col>
                </Row>
                <Row>
                    <Form.Label column>{ t('maintenancedOn') }</Form.Label>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>{ currentMaintenance && moment(currentMaintenance.maintenance_date).format(t('dateFormat')) }</Col>
                </Row>
                <Row>
                    <Form.Label column>{ t('maintenancedBy') }</Form.Label>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>{ currentMaintenance && currentMaintenance.performed_by }</Col>
                </Row>
                <Row>
                    <Form.Label column>{ t('maintenancePhone') }</Form.Label>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>{ currentMaintenance && currentMaintenance.phone }</Col>
                </Row>
                <Row>
                    <Form.Label column>{ t('maintenanceRemote') }</Form.Label>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>{ currentMaintenance && currentMaintenance.remote ? t('yes') : t('no') }</Col>
                </Row>
                <Row>
                    <Form.Label column sm={12}>{ t('maintenanceDescription') }</Form.Label>
                    <Col sm={12}>{ currentMaintenance && currentMaintenance.description }</Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <p style={{ position: 'absolute', left: '1rem', fontSize: 13 }}>{ t('createdOn') } { currentMaintenance && moment(currentMaintenance.created_at).format(t('dateFormat')) }</p>
                <Button variant="secondary" onClick={() => setMaintenanceModal(false)}>{ t('close') }</Button>
            </Modal.Footer>
        </Modal>

    </div>
    )
}

export default MaintenanceScreen