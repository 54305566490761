import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Form, Button, ButtonGroup} from 'react-bootstrap'
import iconGrid from '../images/view-grid.svg'
import iconList from '../images/view-list.svg'

import { axios } from '../scripts/Axios'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import PageHeader from '../components/PageHeader'
import CustomerGridItem from '../components/CustomerGridItem'
import Table from '../components/Table'
//import CustomerListItem from '../components/CustomerListItem'

function CustomerListScreen() {

    const history = useHistory()
    const { customers, user, setCustomers, updateViewMode } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [filter, setFilter] = useState({ search: '' })
    const [filteredCustomers, setFilteredCustomers] = useState()

    /* useEffect(() => {
        setFilteredCustomers(customers)
    }, [customers]) */

    async function addCustomer(){
        let response = await axios.post('customer')
        if(response.status === 200){
            setCustomers([response.data, ...customers])
            history.push('customers/' + response.data.id)
        }
    }


    useEffect(() => {

        let filteredCustomers = customers     

        if(filter.search){    
            let input = filter.search.trim()
            let words = input.split(' ') 

            filteredCustomers = filteredCustomers.filter(c => {
                let matches = words.map(word => {                  
                    if(c.company && c.company.toLowerCase().includes(word.toLowerCase())) return true
                    else if(c.customer_nr && c.customer_nr.toLowerCase().includes(word.toLowerCase())) return true
                    else return false
                })
                for(let i = 0; i < matches.length; i++){
                    if(!matches[i]) return false  
                }
                return true
            })
        }

        setFilteredCustomers(filteredCustomers)

    }, [filter])


    
    function CustomerListView(){
        switch(user.viewmode){
            case 'grid': return <GridView />
            case 'list': return <ListView />
            default: return  <GridView />
        }
    }

    function GridView(){
        return (
            <Row>
            {filteredCustomers && filteredCustomers.map((customer, index) => (
                <Col key={'customerItem' + index} lg={3}>
                    <CustomerGridItem customer={customer} />
                </Col>
            ))}
            </Row>
        )
    }

    function ListView(){
        return (
            <div className="box">
                <Table data={filteredCustomers} routeBase="/customers/" headers={[
                    {
                        label: t('company'),
                        field: 'company',
                        type: 'text',
                        sortable: true,
                        fontWeight: 700,
                    },
                    {
                        label: t('customerNr'),
                        field: 'customer_nr',
                        type: 'text',
                        sortable: true,
                    },
                    {
                        label: t('customerPin'),
                        field: 'pin_active',
                        type: 'bool',
                        sortable: true,
                    },
                ]} />
            </div>
        )
    }

    return (
    <div>
        
        <PageHeader title={ t('menuCustomers') } />

        <div id="pageActions">
            <div className="container">
                <Form.Control size="lg" type="search" placeholder={ t('search') + ' ...' } onChange={e => setFilter({ ...filter, search: e.target.value })} style={{maxWidth: 400}} />
                <ButtonGroup className="gridButtons">
                    <Button variant="primary" size="lg" className={user.viewmode === 'grid' ? 'active' : ''} onClick={() => updateViewMode('grid')}><img src={iconGrid} alt="icon" /></Button>
                    <Button variant="primary" size="lg" className={user.viewmode === 'list' ? 'active' : ''} onClick={() => updateViewMode('list')}><img src={iconList} style={{width: 25, height: 25}} alt="icon" /></Button>
                </ButtonGroup>
                <Button variant="primary" size="lg" style={{marginLeft: 'auto'}} onClick={addCustomer}>{ t('createCustomer') }</Button>
            </div>
        </div>

        <div id="pageContent">
            <div className="container">

                <CustomerListView />

            </div>
        </div>

    </div>
    )
}

export default CustomerListScreen