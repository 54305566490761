import React, { useState, useContext, useEffect } from 'react'
import { useParams, Prompt, useHistory } from 'react-router-dom'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import moment, { parseZone } from 'moment'
import filesize from 'filesize'
import iconPlus from '../../images/plus.svg'
import iconDownload from '../../images/download.svg'
import iconDelete from '../../images/delete.svg'
import iconTrash from '../../images/trash.svg'
import iconLoading from '../../images/loader-button.svg'

import Window from '../../components/Window'
import { axios } from '../../scripts/Axios'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { getMimeIcon } from '../../scripts/Helpers'
import Loader from '../../components/Loader'

function MachineUploadsScreen() {

    const history = useHistory()
    const { id } = useParams()
    const { machines, customers } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [machine, setMachine] = useState()
    const [customer, setCustomer] = useState()

    const [loading, setLoading] = useState(true)

    const [recipients, setRecipients] = useState([])
    const [recipientsChanged, setRecipientsChanged] = useState(false)
    const [savingRecipients, setSavingRecipients] = useState(false)
    const [deleteRecipients, setDeleteRecipients] = useState([])

    const [ftpFolderCreated, setFtpFolderCreated] = useState(false)
    const [ftpEnabled, setFtpEnabled] = useState(false)
    const [files, setFiles] = useState([])

    useEffect(() => {
        getFtpFolder()
    }, [])
    
    useEffect(() => {
        if(machines){
            setMachine(machines.find(m => m.id === parseInt(id)))
        }
    }, [machines])

    useEffect(() => {
        if(machine){
            setCustomer(customers.find(c => c.id === parseInt(machine.customer_id)))
        }
    }, [machine])
    
    function addRecipient(){
        setRecipients([
            ...recipients,
            {
                machine_id: id,
                email: '',
            },
        ])
        setRecipientsChanged(true)
    }

    function changeRecipient(index, value){
        let updatedRecipients = recipients.map((recipient, recipientIndex) => {
            if(index !== recipientIndex){
                return recipient
            }else{
                return {
                    ...recipient,
                    email: value,
                }
            }
        })
        setRecipients(updatedRecipients)
        setRecipientsChanged(true)
    }

    async function saveRecipients(){
        setSavingRecipients(true)
        try {
            let response = await axios.put('machine/' + id + '/ftp/recipients', {
                updateRecipients: recipients.filter(recipient => recipient.id),
                createRecipients: recipients.filter(recipient => !recipient.id),
                deleteRecipients: deleteRecipients,
            })
            if(response.status === 200){

                setRecipients(response.data)
                setDeleteRecipients([])

                setSavingRecipients(false)
                setRecipientsChanged(false)

            }
        } catch(err) {
            console.log(err)
        }
    }

    function deleteRecipient(id){
        if(id){
            let delRecipient = recipients.find(recipient => parseInt(recipient.id) === parseInt(id))
            if(delRecipient.id){
                setDeleteRecipients([...deleteRecipients, delRecipient])
            }
        }
        setRecipients(recipients.filter(recipient => recipient.id !== id))
        setRecipientsChanged(true)
    }


    async function getFtpFolder(){        
        let response = await axios.get('machine/' + id + '/ftp')
        if(response.status === 200){
            if(response.data){
                setFtpFolderCreated(true)
                setFtpEnabled(response.data.enabled)
                setRecipients(response.data.recipients)
                setFiles(response.data.files)
            }
            setLoading(false)
        }
    }

    async function createFtpFolder(){
        if(!customer.pin_active){
            let q = window.confirm(t('alertPinNeeded'))
            if(q) history.push('/customers/' + customer.id)
            return
        }

        let response = await axios.post('machine/' + machine.id + '/ftp-folder')
        if(response.status === 200){
            setFtpEnabled(true)
            setFtpFolderCreated(true)
        }
    }

    async function toggleFtpFolder(){        
        let response = await axios.put('machine/' + machine.id + '/toggle-ftp')
        if(response.status === 200){
            setFtpEnabled(!ftpEnabled)
        }
    }


    async function deleteFile(fileId){
        let q = window.confirm('Möchten Sie diese Datei unwiderruflich löschen?')
        if(!q) return
    
        setLoading(true)
        let response = await axios.delete('/ftp/file/' + fileId)
        if(response.status === 200){
            setFiles(prev => prev.filter(f => f.id !== fileId))
            setLoading(false)
        }
    }

    

    const viewFile = async (uri, name) => {
        let file = await loadFile(uri)
        //window.open(file, '_blank')
        let anchor = document.createElement('a')
        anchor.download = name
        anchor.href = file
        anchor.click()
    }

    const loadFile = async (uri) => {
        let url
        let response = await axios.get(uri, {responseType: 'blob'})
        if(response.status === 200){
            url = URL.createObjectURL(response.data)
        }
        return Promise.resolve(url)
    }

    const SortableFileList = SortableContainer(({ t, items }) => {
        return (
        <div className="fileList">
            {items.length < 1 ? (
                <div className="grey">{ t('noFiles') }</div>
            ):(
                items.map((file, index) => {
                    return <SortableFile t={t} key={'file' + file.id} index={index} file={file} />
                })
            )}
        </div>
        )
    })

    const SortableFile = SortableElement(({ t, file }) => (
        <Row className="file">
            <Col className="fileIcon">
                <img src={getMimeIcon(file.mime)} alt="icon" />
            </Col>
            <Col>
                <div className="fileTitle">{file.name}</div>
                <div className="fileMeta">{file.file.split('.')[1]} • {filesize(file.size)} • { t('from') } {moment(file.created_at).format(t('dateFormat'))}</div>
            </Col>
            {file.id &&
            <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
                <div className="viewButton" onClick={() => deleteFile(file.id)}>
                    <img src={iconTrash} alt="icon" />
                </div>
            </Col>
            }
            {file.id &&
            <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
                <div className="viewButton" onClick={() => viewFile(file.uri, file.name)}>
                    <img src={iconDownload} alt="icon" />
                </div>
            </Col>
            }
        </Row>
    ))
    

    return (
    <div className="box">
                
        {loading && <Loader />}

        <Prompt when={recipientsChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col lg={4}>
                
                <Window title={ t('uploadRecipients') } actions={() => (
                    <div className="windowActions">
                        {ftpFolderCreated && <Button variant="secondary" className="iconButton" onClick={addRecipient}><img src={iconPlus} alt="icon" /></Button>}
                        {ftpFolderCreated &&
                            <Button variant="success" disabled={!recipientsChanged} onClick={saveRecipients}>
                                { savingRecipients ? <img src={iconLoading} alt="icon" /> : t('save') }
                            </Button>
                        }
                    </div>
                )} style={{marginBottom: 20}}>

                    {ftpFolderCreated ? (                        
                        <div className="recipientList">
                            {recipients.length < 1 ? (
                                <div className="grey">{ t('noRecipients') }</div>
                            ):(
                                recipients.map((recipient, i) => (
                                    <Row className="file" key={'recipient'+i}>
                                        <Col>
                                            <Form.Control type="email" value={recipient.email || ''} onChange={e => changeRecipient(i, e.target.value)} placeholder={ t('email') } />
                                        </Col>
                                        <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
                                            <div className="listButton" onClick={() => deleteRecipient(recipient.id)}>
                                                <img src={iconDelete} alt="icon" />
                                            </div>
                                        </Col>
                                    </Row>
                                ))
                            )}
                        </div>
                    ):(
                        <div className="grey">{ t('noFtpFolder') }</div>
                    )}

                </Window>

            </Col>
            <Col lg={8}>

                <Window title={ t('fileUploads') } actions={() => (
                    <div className="windowActions">
                        {ftpEnabled ? <Button variant="secondary" onClick={toggleFtpFolder}>{ t('deactivate') }</Button> : null}
                        {(ftpFolderCreated && !ftpEnabled) ? <Button variant="success" onClick={toggleFtpFolder}>{ t('activate') }</Button> : null}
                    </div>
                )}>

                    { ftpFolderCreated ? (
                        files && <SortableFileList t={t} items={files} useDragHandle={true} />
                    ):(
                        <Button variant="primary" className="activeIconButton" size="large" onClick={createFtpFolder}><img src={iconPlus} alt="icon" style={{ marginTop: 3 }} /><span>{ t('createFtpFolder') }</span></Button>
                    )}

                </Window>

            </Col>
        </Row>
    </div>
    )
}



export default MachineUploadsScreen