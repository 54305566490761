import React, { useState, useContext, useEffect } from 'react'
import { useParams, Prompt } from 'react-router-dom'
import { Row, Col, Button, Form, ButtonGroup, ToggleButton } from 'react-bootstrap'
import iconLoading from '../../images/loader-button.svg'

import { axios } from '../../scripts/Axios'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import Window from '../../components/Window'

function CustomerGeneralScreen() {

    const { id } = useParams()
    const { customers, setCustomers, checkFields } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [customer, setCustomer] = useState()

    const [customerChanged, setCustomerChanged] = useState(false)
    const [savingCustomer, setSavingCustomer] = useState(false)

    useEffect(() => {
        if(customers && id){
            setCustomer(customers.find(c => c.id === parseInt(id)))
        }
    }, [customers, id])


    function changeCustomer(field, value){
        setCustomer({
            ...customer,
            [field]: value,
        })
        setCustomerChanged(true)
    }

    async function saveCustomer(){
        let check = checkFields([customer], ['company'])
        if(!check){ return }
        setSavingCustomer(true)
        let response = await axios.put('customer/' + customer.id, customer)
        if(response.status === 200){
            setCustomers(customers.map(c => {
                return c.id === customer.id ? response.data : c
            }))
            setSavingCustomer(false)
            setCustomerChanged(false)
        }
    }

    return (
    <div className="box">

        <Prompt when={customerChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col lg={7}>

                <Window title={ t('customerData') } actions={() => (
                    <div className="windowActions">
                        <Button variant="success" disabled={!customerChanged} onClick={saveCustomer}>
                            { savingCustomer ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>

                    <div className="inputList">
                        <Row>
                            <Form.Label column sm={4}>{ t('customerPin') }</Form.Label>
                            <Col sm={8}>
                                <Row>
                                    <Col md="auto">
                                        <ButtonGroup size="sm" toggle>
                                            <ToggleButton type="radio" variant="secondary" checked={customer ? parseInt(customer.pin_active) === 1 : false} onChange={(e) => changeCustomer('pin_active', 1)}>{ t('active') }</ToggleButton>
                                            <ToggleButton type="radio" variant="secondary" checked={customer ? parseInt(customer.pin_active) === 0 : false} onChange={(e) => changeCustomer('pin_active', 0)}>{ t('inactive') }</ToggleButton>
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        <Form.Control value={customer && customer.pin || ''} onChange={e => changeCustomer('pin', e.target.value)} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('customerNr') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={customer && customer.customer_nr || ''} onChange={e => changeCustomer('customer_nr', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('language') }</Form.Label>
                            <Col sm={8}>                            
                                <Form.Control as="select" value={customer && customer.language} onChange={e => changeCustomer('language', e.target.value)}>
                                    <option value="de">{ t('german') }</option>
                                    <option value="en">{ t('english') }</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('company') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={customer && customer.company || ''} onChange={e => changeCustomer('company', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('address') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control as="textarea" rows={3} value={customer && customer.address || ''} onChange={e => changeCustomer('address', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('email') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={customer && customer.email || ''} onChange={e => changeCustomer('email', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('mobile') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={customer && customer.mobile || ''} onChange={e => changeCustomer('mobile', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('phone') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={customer && customer.phone || ''} onChange={e => changeCustomer('phone', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('fax') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={customer && customer.fax || ''} onChange={e => changeCustomer('fax', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm={4}>{ t('website') }</Form.Label>
                            <Col sm={8}>
                                <Form.Control value={customer && customer.website || ''} onChange={e => changeCustomer('website', e.target.value)} />
                            </Col>
                        </Row>
                    </div>

                </Window>

            </Col>
            <Col lg={5}>

                <Window title={ t('subscription') }>
                    ...
                </Window>

            </Col>
        </Row>
    </div>
    )
}

export default CustomerGeneralScreen