import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../images/placeholder.jpg'

import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function MachineGridItem({ machine }) {

    const { customers } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const customer = customers.find(customer => parseInt(customer.id) === parseInt(machine.customer_id))

    return (
        <Link to={'/machines/' + machine.id} className="gridItem box">
            <div className="gridImage" style={{backgroundImage: 'url(' + ((machine && machine.image_cache) || Placeholder) + ')'}}></div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="gridTitle">{machine.name}</div>
                <div className={parseInt(machine.status) === 1 ? 'statusCircle active' : 'statusCircle inactive'}></div>
            </div>
            <div className="gridMeta">{ t('serialnr') } {machine.serial_nr}</div>
            <div className="gridMeta">{customer ? customer.company : <div className="grey">{ t('notAssigned') }</div>}</div>
        </Link>
    )
}

export default MachineGridItem