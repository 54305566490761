import React, { useContext, useState, useEffect } from 'react'
import { Switch, Route, NavLink, useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { Button, ButtonGroup } from 'react-bootstrap'
import iconTrash from '../images/trash.svg'

import PageHeader from '../components/PageHeader'
import CustomerGeneralScreen from '../screens/customer/General'
import CustomerMachinesScreen from '../screens/customer/Machines'

import { axios } from '../scripts/Axios'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function CustomerScreen() {

    const { id } = useParams()
    const history = useHistory()
    const route = useRouteMatch()

    const { customers, setCustomers } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [customer, setCustomer] = useState()

    useEffect(() => {
        if(customers && id){
            setCustomer(customers.find(c => c.id === parseInt(id)))
        }
    }, [customers, id])

    async function deleteCustomer(){
        let q = window.confirm(t('alertDeleteCustomer'))
        if(!q){ return }
        let response = await axios.delete('customer/' + customer.id)
        if(response.status === 200){
            let updatedCustomers = customers.filter(c => c.id !== customer.id)
            setCustomers(updatedCustomers)
            history.push('/customers')
        }
    }


    return (
    <div>
        
        <PageHeader title={customer && customer.company} />
        
        <div id="pageActions">
            <div className="container">
                <ButtonGroup>
                    <NavLink exact to={route.url} activeClassName="active">
                        <Button variant="primary" size="lg">{ t('menuCustomerGeneral') }</Button>
                    </NavLink>
                    <NavLink to={route.url + '/machines'} activeClassName="active">
                        <Button variant="primary" size="lg">{ t('menuCustomerMachines') }</Button>
                    </NavLink>
                </ButtonGroup>
                <Button variant="primary" size="lg" className="iconButton" style={{marginLeft: 'auto'}} onClick={deleteCustomer}><img src={iconTrash} alt="icon" /></Button>
            </div>
        </div>

        <div id="pageContent">
            <div className="container">

                <Switch>
                    <Route exact path={route.path}>
                        <CustomerGeneralScreen />
                    </Route>
                    <Route path={route.path + '/machines'}>
                        <CustomerMachinesScreen />
                    </Route>
                </Switch>

            </div>
        </div>

    </div>
    )
}

export default CustomerScreen