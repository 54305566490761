import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import iconGrid from '../images/view-grid.svg'
import iconList from '../images/view-list.svg'

import { axios } from '../scripts/Axios'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import PageHeader from '../components/PageHeader'
import MachineGridItem from '../components/MachineGridItem'
//import MachineListItem from '../components/MachineListItem'
import Table from '../components/Table'

function MachineListScreen() {

    const history = useHistory()
    const { user, machines, customers, setMachines, updateViewMode } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [filter, setFilter] = useState({
        search: '',
        customer: '',
    })
    const [filteredMachines, setFilteredMachines] = useState(machines)

    /* useEffect(() => {
        setFilteredMachines(machines)
    }, [machines]) */

    async function addMachine(){
        let response = await axios.post('machine')
        if(response.status === 200){
            setMachines([response.data, ...machines])
            history.push('machines/' + response.data.id)
        }
    }    

    useEffect(() => {

        let filteredMachines = machines

        if(filter.customer){
            filteredMachines = filteredMachines.filter(m => {
                if(filter.customer === 'none') return !m.customer_id
                else return parseInt(m.customer_id) === parseInt(filter.customer) ? true : false
            })
        }        

        if(filter.search){    
            let input = filter.search.trim()
            let words = input.split(' ') 

            filteredMachines = filteredMachines.filter(m => {
                let matches = words.map(word => {                  
                    if(m.name && m.name.toLowerCase().includes(word.toLowerCase())) return true
                    else if(m.serial_nr && m.serial_nr.toLowerCase().includes(word.toLowerCase())) return true
                    else return false
                })
                for(let i = 0; i < matches.length; i++){
                    if(!matches[i]) return false  
                }
                return true
            })
        }

        setFilteredMachines(filteredMachines)

    }, [filter])


    function MachineListView(){
        switch(user.viewmode){
            case 'grid': return <GridView />
            case 'list': return <ListView />
            default: return  <GridView />
        }
    }

    function GridView(){
        return (
            <Row>
            {filteredMachines && filteredMachines.map((machine, index) => (
                <Col key={'machineItem' + index} lg={3}>
                    <MachineGridItem machine={machine} />
                </Col>
            ))}
            </Row>
        )
    }

    function ListView(){
        return (
            <div className="box">
                <Table data={filteredMachines} routeBase="/machines/" headers={[
                    {
                        field: 'status',
                        type: 'status',
                        sortable: false,
                        width: 40,
                    },
                    {
                        label: t('name'),
                        field: 'name',
                        type: 'text',
                        sortable: true,
                        fontWeight: 700,
                    },
                    {
                        label: t('serialnr'),
                        field: 'serial_nr',
                        type: 'text',
                        sortable: true,
                    },
                    {
                        label: t('customer'),
                        field: 'customer_id',
                        type: 'customer',
                        sortable: true,
                    },
                ]} />
            </div>
        )
    }

    return (
    <div>
        
        <PageHeader title={ t('menuMachines') } />

        <div id="pageActions">
            <div className="container">
                <Form.Control size="lg" type="search" placeholder={ t('search') + ' ...' } onChange={e => setFilter({...filter, search: e.target.value})} style={{maxWidth: 300, marginRight: 15}} />
                <Form.Control as="select" size="lg" style={{maxWidth: 300}} onChange={e => setFilter({...filter, customer: e.target.value})}>
                    <option value="">{ t('allCustomers') }</option>
                    <option value="none">{ t('notAssigned') }</option>
                    {customers.map((customer, index) => <option key={'customer' + index} value={customer.id}>{customer.company}</option>)}
                </Form.Control>
                <ButtonGroup className="gridButtons">
                    <Button variant="primary" size="lg" className={user.viewmode === 'grid' ? 'active' : ''} onClick={() => updateViewMode('grid')}><img src={iconGrid} alt="icon" /></Button>
                    <Button variant="primary" size="lg" className={user.viewmode === 'list' ? 'active' : ''} onClick={() => updateViewMode('list')}><img src={iconList} style={{width: 25, height: 25}} alt="icon" /></Button>
                </ButtonGroup>
                <Button variant="primary" size="lg" style={{marginLeft: 'auto'}} onClick={addMachine}>{ t('createMachine') }</Button>
            </div>
        </div>

        <div id="pageContent">
            <div className="container">

                <MachineListView />

            </div>
        </div>

    </div>
    )
}

export default MachineListScreen