import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function CustomerGridItem({ customer }) {

    const { machines } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const machineCount = machines.filter(m => parseInt(m.customer_id) === parseInt(customer.id)).length

    return (
        <Link to={'/customers/' + customer.id} className="gridItem box">
            <div className="gridTitle">{customer.company}</div>
            <div className="gridMeta">{customer.customer_nr}</div>
            {1 === 0 ? <div className="listSubtitle green">{ t('activeSubscription') }</div> : <div className="gridSubtitle grey">{ t('noSubscription') }</div>}
            <div className="gridSubtitle">{machineCount} { t('machineCountLabel') }</div>
        </Link>
    )
}

export default CustomerGridItem