import React from 'react'

function Window({ title, actions = () => {}, children, style = null }){
    return (
    <div className="window" style={style}>
        <div className="windowHead">
            <div className="windowTitle">{ title }</div>
            { actions() }
        </div>
        <div className="windowContent">
            { children }
        </div>
    </div>
    )
}

export default Window