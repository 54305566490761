export const de = {
    // Languages
    'language': 'Sprache',
    'german': 'Deutsch',
    'english': 'Englisch',

    // Date Format
    'dateFormat': 'DD.MM.YYYY',

    // Main Menu
    'menuMachines': 'Maschinen',
    'menuDocumentation': 'Dokumentation',
    'menuCustomers': 'Kunden',
    'menuEvents': 'Termine',
    'menuMaintenance': 'Wartung',
    'menuService': 'Service-Seite',
    'menuHistory': 'Historie',
    'menuSettings': 'Einstellungen',
    'menuLogout': 'Logout',

    // Machine Menu
    'menuMachineGeneral': 'Allgemein',
    'menuMachineEvents': 'Termine',
    'menuMachineDocumentation': 'Dokumentation',
    'menuMachineTutorials': 'Tutorials',
    'menuMachineUploads': 'Uploads',

    // Customer Menu
    'menuCustomerGeneral': 'Allgemein',
    'menuCustomerMachines': 'Maschinen',

    // Settings Menu
    'menuSettingsUsers': 'Benutzer',
    'menuSettingsEvents': 'Termine',
    'menuSettingsFields': 'Felder',
    'menuSettingsEmails': 'E-Mails',
    'menuSettingsApp': 'App',

    // General
    'logo': 'Logo',
    'search': 'Suchen',
    'save': 'Speichern',
    'password': 'Passwort',
    'firstAndLastname': 'Vor- und Nachname',
    'email': 'E-Mail',
    'text': 'Text',
    'number': 'Zahl',
    'date': 'Datum',
    'user': 'Benutzer',
    'allUsers': 'Alle Benutzer',
    'allCustomers': 'Alle Kunden',
    'allEvents': 'Alle Termine',
    'userDeleted': 'Benutzer gelöscht',
    'system': 'System',
    'activity': 'Aktivität',
    'showMore': 'Mehr anzeigen',
    'machine': 'Maschine',
    'customer': 'Kunde',
    'createMachine': 'Maschine anlegen',
    'createCustomer': 'Kunde anlegen',
    'machineCountLabel': 'Maschine(n)',
    'subscription': 'Abonnement',
    'activeSubscription': 'Aktives Abonnement',
    'noSubscription': 'Kein Abonnement',
    'active': 'Aktiv',
    'inactive': 'Inaktiv',
    'login': 'Einloggen',
    'loginTitle': 'Login',
    'name': 'Name',
    'change': 'Ändern',
    'edit': 'Bearbeiten',
    'cancel': 'Abbrechen',
    'status': 'Status',
    'print': 'Drucken',
    'image': 'Bild',
    'qrcode': 'QR-Code',
    'location': 'Standort',
    'back': 'Zurück',
    'from': 'vom',
    'by': 'Von',
    'folders': 'Ordner',
    'files': 'Dateien',
    'activate': 'Aktivieren',
    'deactivate': 'Deaktivieren',
    'yes': 'Ja',
    'no': 'Nein',
    'close': 'Schliessen',
    'users': 'Benutzer',
    'serialnr': 'Seriennummer',
    'copy': 'Kopieren',
    'copied': 'Kopiert',
    'loading': 'Lädt',

    // User Roles
    'roleStaff': 'Mitarbeiter',
    'roleAdmin': 'Administrator',

    // Alerts
    'alertUnsavedChanges': 'Es gibt ungespeicherte Änderungen. Wollen Sie diese Seite wirklich verlassen und die Änderungen verwerfen?',
    'alertFillFields': 'Bitte füllen Sie alle notwendigen Felder aus.',
    'alertDeleteCustomer': 'Möchten Sie diesen Kunden wirklich unwiderruflich löschen?',
    'alertDeleteMachine': 'Möchten Sie diese Maschine wirklich inklusive aller zugehörigen Dateien und Informationen unwiderruflich löschen?',
    'alertWrongLogin': 'Die Zugangsdaten sind nicht korrekt.',
    'alertPinNeeded': 'Bitte hinterlegen Sie zuerst eine PIN.',
    
    // Machine Screen
    'notAssigned': 'Nicht zugewiesen',
    'transferMachine': 'Maschine übertragen',
    'machineData': 'Maschinendaten',
    'noEvents': 'Keine Termine vorhanden',
    'chooseEventType': 'Terminart wählen',
    'noRepeat': 'keine Wiederholung',
    'dailyRepeat': 'tägliche Wiederholung',
    'weeklyRepeat': 'wöchentlichte Wiederholung',
    'monthlyRepeat': 'monatliche Wiederholung',
    'yearlyRepeat': 'jährliche Wiederholung',
    'unnamedFolder': 'Unbenannter Ordner',
    'noFolders': 'Keine Ordner vorhanden',
    'noFiles': 'Keine Dateien vorhanden',
    'noRecipients': 'Keine Empfänger vorhanden',
    'noFtpFolder': 'Kein FTP-Ordner angelegt',
    'createFtpFolder': 'FTP-Ordner erstellen',
    'fileUploads': 'Datei Uploads',
    'uploadRecipients': 'Upload Empfänger',
    'downloadPdf': 'Download PDF',

    // Transfer
    'transferWarning': 'Bei der Übertragung zu einem anderen Kunden wird ein neuer QR-Code für diese Maschine generiert. Dadurch wird der bisherige QR-Code nicht mehr funktionieren.',
    'transferMachine': 'Maschine übertragen',
    'transfer': 'Übertragen',
    'chooseDestination': 'Ziel wählen',

    // Documentation Screen
    'globalFilesInfo': 'Folgende globale Dateien sind in jeder Maschine sichtbar. Bitte laden Sie Maschinen-spezifische Dateien direkt in der jeweiligen Maschine hoch.',

    // Customer Screen
    'customerData': 'Kundendaten',
    'customerPin': 'Kunden-PIN',
    'customerNr': 'Kunden-Nr.',
    'company': 'Firma',
    'address': 'Adresse',
    'mobile': 'Mobil',
    'phone': 'Telefon',
    'fax': 'Fax',
    'website': 'Webseite',

    // Events Screen
    'noReminder': 'Keine Erinnerung',
    'reminderAt': 'Erinnerung am',
    'reminderSentAt': 'Erinnerung gesendet am',
    'reminderTodayAt12': 'Erinnerung heute um 12:00',
    'confirmationPending': 'Bestätigung ausstehend',
    'eventConfirmed': 'Termin bestätigt',

    // Maintenance Screen
    'allMachines': 'Alle Maschinen',
    'allCustomers': 'Alle Kunden',
    'maintenanceDetails': 'Wartungsdetails',
    'createdOn': 'Erstellt am',
    'editedOn': 'Bearbeitet am',
    'maintenancedOn': 'Durchgeführt am',
    'maintenancedBy': 'Durchgeführt von',
    'maintenancePhone': 'Rufummer für Rückfragen',
    'maintenanceDescription': 'Wartungs-Beschreibung',
    'maintenanceRemote': 'Fernwartung',
    'remote': 'Fernwartung',

    // Service Screen
    'serviceText': 'Service-Text',

    // Settings Screen
    'eventTypes': 'Terminarten',
    'eventName': 'Terminbezeichnung',
    'remindDaysBefore': 'Tage davor erinnern',
    'showAlwaysInFrontend': 'Immer im Frontend anzeigen',
    'confirmationRequired': 'Bestätigung erforderlich',
    'showInFrontend': 'Im Frontend anzeigen',
    'machineFields' : 'Maschinen-Felder',
    'unnamedField': 'Unbenanntes Feld',
    'appSettings': 'App-Einstellungen',
    'changeLogo': 'Logo ändern',
    'appLanguage': 'Backend Sprache',
    'primaryAppColor': 'Primäre App-Farbe',
    'secondaryAppColor': 'Sekundäre App-Farbe',
    'eventReminder': 'Terminerinnerung',
    'eventConfirmation': 'Terminbestätigung',
    'subject': 'Betreff',
    'variables': 'Variablen',
}