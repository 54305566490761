import React, { useContext } from 'react'
import { Document, Page, Text, Image, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer"

import { LocalizationContext } from '../../scripts/LocalizationContext'
import Logo from '../../images/tresky-logo.jpg'

const documentSize = [283, 213] // is in px, at 72dpi it is 100x75mm

const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      color: "#000",
    },
    qr: {
        position: 'absolute',
        top: 8,
        left: 8,
        width: 140,
    },
    logo: {
        position: 'absolute',
        top: 20,
        right: 20,
        width: 110,
    },
    text: {
        position: 'absolute',
        top: 160,
        left: 20,
        fontSize: 28,
        textTransform: 'uppercase',
    }
});


function PrintQrButton({ qr, logo, machine })
{
    const { t } = useContext(LocalizationContext)

    return (
    <PDFDownloadLink document={(
        <Document>

            <Page size={documentSize} style={styles.page}>
                <Image style={styles.qr} src={qr} />
                <Image style={styles.logo} src={Logo} />
                <Text style={styles.text}>{ t('menuMachineDocumentation') }</Text>
            </Page>

        </Document>
    )} fileName={'QR Code ' + machine.name + '.pdf'} className="btn">
        {({ blob, url, loading, error }) =>
            loading ? t('loading') + ' ...' : t('downloadPdf')
        }
    </PDFDownloadLink>
    )
}

export default PrintQrButton;