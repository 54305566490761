import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { InputGroup, Form, Button } from 'react-bootstrap'
import iconUser from '../images/user.svg'
import iconLock from '../images/lock.svg'

import { axios } from '../scripts/Axios'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function LoginScreen() {

    const history = useHistory()
    const { setUser, setToken, setLoading, appSettings } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    async function login(){
        setLoading(true)
        let response = await axios.post('login', {
            email: email,
            password: password,
        })
        if(response.status === 200){
            if(response.data.user && response.data.token){
                setLoading(false)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                localStorage.setItem('token', response.data.token)
                setUser(response.data.user)
                setToken(response.data.token)
                history.push('/machines')
            }else{
                setLoading(false)
                alert(t('alertWrongLogin'))
            }
        }
    }

    return (
    <div>

        <div id="loginContent">
            <div className="container">

                { appSettings && <img src={appSettings.appLogo} id="logo" style={{alignSelf:'center'}} alt="logo" /> }

                { appSettings &&
                    <div className="box">
                        <h5 style={{marginBottom: 20}}>{appSettings.appName} { t('loginTitle') }</h5>
                        <form onSubmit={login}>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><img src={iconUser} alt="icon" /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control size="lg" placeholder={ t('email') } value={email} onChange={e => setEmail(e.target.value)} />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><img src={iconLock} alt="icon" /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control size="lg" type="password" placeholder={ t('password') } value={password} onChange={e => setPassword(e.target.value)} />
                            </InputGroup>
                            <Button type="submit" className="loginButton" onClick={login}>{ t('login') }</Button>
                        </form>
                    </div>
                }

            </div>
        </div>

    </div>
    )
}

export default LoginScreen