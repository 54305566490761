import React from 'react'
import iconLoader from '../images/loader.svg'

function Loader(){
    return (
    <div id="loader">
        <img src={iconLoader} width={64} height={64} />
    </div>
    )
}

export default Loader