import mimeFile from '../images/mimes/file.svg'
import mimeCSV from '../images/mimes/csv.svg'
import mimeDOC from '../images/mimes/doc.svg'
import mimeJPG from '../images/mimes/jpg.svg'
import mimeMP3 from '../images/mimes/mp3.svg'
import mimeMP4 from '../images/mimes/mp4.svg'
import mimePDF from '../images/mimes/pdf.svg'
import mimePNG from '../images/mimes/png.svg'
import mimePPT from '../images/mimes/ppt.svg'
import mimeTXT from '../images/mimes/txt.svg'
import mimeXLS from '../images/mimes/xls.svg'
import mimeZIP from '../images/mimes/zip.svg'

const getMimeIcon = (mime) => {
    let icon = mimeFile
    switch(mime){
        case 'text/csv':
            icon = mimeCSV
            break
        case 'application/msword':
            icon = mimeDOC
            break
        case 'image/jpeg':
            icon = mimeJPG
            break
        case 'audio/mpeg':
            icon = mimeMP3
            break
        case 'video/mp4':
            icon = mimeMP4
            break
        case 'application/pdf':
            icon = mimePDF
            break
        case 'image/png':
            icon = mimePNG
            break
        case 'application/vnd.ms-powerpoint':
            icon = mimePPT
            break
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            icon = mimePPT
            break
        case 'application/vnd.ms-excel':
            icon = mimeXLS
            break
        case 'text/plain':
            icon = mimeTXT
            break
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            icon = mimeXLS
            break
        case 'application/zip':
            icon = mimeZIP
            break
    }
    return icon
}

export { getMimeIcon }