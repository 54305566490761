import React, { useRef, useState, useContext, useEffect } from 'react'
import { useParams, Prompt } from 'react-router-dom'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import moment from 'moment'
import arrayMove from 'array-move'
import filesize from 'filesize'

import iconPlus from '../../images/plus.svg'
import iconDelete from '../../images/delete.svg'
import iconPen from '../../images/pen.svg'
import iconMenu from '../../images/menu.svg'
import iconEye from '../../images/eye.svg'
import iconFolderEmpty from '../../images/folder-empty.svg'
import iconFolder from '../../images/folder.svg'
import iconLoading from '../../images/loader-button.svg'

import Window from '../../components/Window'
import { axios } from '../../scripts/Axios'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { getMimeIcon } from '../../scripts/Helpers'
import Loader from '../../components/Loader'

function MachineDocumentationsScreen() {

    const { id } = useParams()
    const { language } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [loading, setLoading] = useState(true)

    const [activeFolder, setActiveFolder] = useState(null)
    const [activeFolderPath, setActiveFolderPath] = useState('')
    const [folderEditMode, setFolderEditMode] = useState(false)
    const [foldersChanged, setFoldersChanged] = useState(false)
    const [savingFolders, setSavingFolders] = useState(false)
    const [deleteFolders, setDeleteFolders] = useState([])
    const [folderSortingChanged, setFolderSortingChanged] = useState(false)

    const [fileEditMode, setFileEditMode] = useState(false)
    const [filesChanged, setFilesChanged] = useState(false)
    const [savingFiles, setSavingFiles] = useState(false)
    const [uploadFiles, setUploadFiles] = useState([])
    const [uploadFilesData, setUploadFilesData] = useState([])
    const [deleteFiles, setDeleteFiles] = useState([])
    const [fileSortingChanged, setFileSortingChanged] = useState(false)

    const refFileInput = useRef()

    const [folders, setFolders] = useState([])
    const [files, setFiles] = useState([])

    useEffect(() => {
        getDocumentation()
    }, [])

    useEffect(() => setActiveFolder(null), [language])

    useEffect(() => makeFolderPath(), [activeFolder])

    useEffect(() => {
        if(folderSortingChanged === true){
            setFolders(folders.map((folder, index) => ({...folder, sortkey: index})))
            setFolderSortingChanged(false)
        }
    }, [folderSortingChanged])

    useEffect(() => {
        if(fileSortingChanged === true){
            setFiles(files.map((file, index) => ({...file, sortkey: index})))
            setFileSortingChanged(false)
        }
    }, [fileSortingChanged])


    async function getDocumentation(){        
        let response = await axios.get('machine/' + id + '/documentation')
        if(response.status === 200){
            setFolders(response.data.folders)
            setFiles(response.data.files)
            setLoading(false)
        }
    }

    function makeFolderPath(){
        let path = '/'
        if(activeFolder){
            path = ''
            let loop = true
            let currentFolder = activeFolder
            while(loop){
                path = '/' + currentFolder.name + path
                if(currentFolder.parent_id){
                    let parentFolder = folders.find(folder => parseInt(folder.folder_id) === parseInt(currentFolder.parent_id))
                    currentFolder = parentFolder
                }else{
                    loop = false
                }
            }
        }
        setActiveFolderPath(path)
    }

    function addFolder(){
        setFolders([
            ...folders,
            {
                machine_id: id,
                folder_id: makeFolderId(),
                parent_id: activeFolder ? activeFolder.folder_id : null,
                sortkey: folders.length,
                name: t('unnamedFolder'),
                language: language,
            },
        ])
        setFoldersChanged(true)
    }

    function makeFolderId(){
        let maxId = 0
        folders.map(folder => {
            if(parseInt(folder.folder_id) > maxId){
                maxId = parseInt(folder.folder_id)
            }
        })
        return maxId + 1
    }

    function changeFolder(folderId, value){
        let updatedFolders = folders.map(folder => {
            return parseInt(folder.folder_id) === parseInt(folderId) ? {
                ...folder,
                name: value
            } : folder
        })
        setFolders(updatedFolders)
        setFoldersChanged(true)
    }

    function deleteFolder(folderId){
        let delFolder = folders.find(folder => parseInt(folder.folder_id) === parseInt(folderId))
        if(delFolder.id){
            setDeleteFolders([...deleteFolders, delFolder])
        }        
        setFolders(folders.filter(folder => folder.folder_id !== folderId))
        setFoldersChanged(true)
    }

    async function saveFolders(){
        setSavingFolders(true)
        try {
            let response = await axios.put('machine/' + id + '/documentation/folders', {
                updateFolders: folders.filter(folder => folder.id),
                createFolders: folders.filter(folder => !folder.id),
                deleteFolders: deleteFolders,
            })
            if(response.status === 200){

                setFolders(response.data)
                setDeleteFolders([])

                setSavingFolders(false)
                setFoldersChanged(false)

                setFolderEditMode(false)
                setFileEditMode(false)

            }
        } catch(err) {
            console.log(err)
        }
    }

    function onFoldersSorted({oldIndex, newIndex}) {
        if(oldIndex !== newIndex){
            setFolders(arrayMove(folders, oldIndex, newIndex))
            setFolderSortingChanged(true)
            setFoldersChanged(true)
        }
    }

    function openParentFoder(){
        setActiveFolder(folders.find(folder => parseInt(folder.folder_id) === parseInt(activeFolder.parent_id)))
    }

    function addFiles(e){
        if(e.target.files.length){
            let newFiles = []
            Array.from(e.target.files).map((file, index) => {
                newFiles.push({
                    machine_id: id,
                    folder_id: activeFolder ? activeFolder.folder_id : null,
                    file_id: makeFileId(index),
                    sortkey: files.length + index,
                    name: file.name.split('.')[0],
                    file: file.name,
                    mime: file.type,
                    size: file.size,
                    language: language,
                })
            })
            setUploadFiles([...e.target.files, ...uploadFiles])
            setUploadFilesData([...newFiles, ...uploadFilesData])
            setFiles([...files, ...newFiles])
            setFilesChanged(true)
        }
    }

    function makeFileId(index){
        let maxId = 0
        files.map(file => {
            if(parseInt(file.file_id) > maxId){
                maxId = parseInt(file.file_id)
            }
        })
        return maxId + index + 1
    }

    function changeFile(fileId, value){
        let updatedFiles = files.map(file => {
            return parseInt(file.file_id) === parseInt(fileId) ? {
                ...file,
                name: value,
            } : file
        })
        setFiles(updatedFiles)
        setFilesChanged(true)
    }

    function deleteFile(fileId){
        let delFile = files.find(file => parseInt(file.file_id) === parseInt(fileId))
        if(delFile.id){
            setDeleteFiles([...deleteFiles, delFile])
        }        
        setFiles(files.filter(file => file.file_id !== fileId))
        setFilesChanged(true)
    }

    async function saveFiles(){
        setSavingFiles(true)
        try {

            let formData = new FormData()
            formData.append('updateFiles', JSON.stringify(files.filter(file => file.id)))
            Array.from(uploadFiles).map((file, index) => {
                let data = uploadFilesData.find((d, dataIndex) => dataIndex === index)
                formData.append('createFiles[]', file)
                formData.append('createFilesData[]', JSON.stringify(data))
            })
            formData.append('deleteFiles', JSON.stringify(deleteFiles))

            let response = await axios.post('machine/' + id + '/documentation/files', formData)
            if(response.status === 200){

                setFiles(response.data)
                setUploadFiles([])
                setUploadFilesData([])
                setDeleteFiles([])

                setSavingFiles(false)
                setFilesChanged(false)

                setFileEditMode(false)
                setFolderEditMode(false)

            }

        } catch(err) {
            console.log(err)
        }
    }

    function onFilesSorted({oldIndex, newIndex}) {
        if(oldIndex !== newIndex){
            setFiles(arrayMove(files, oldIndex, newIndex))
            //setUploadFiles(arrayMove(uploadFiles, oldIndex, newIndex))
            //setUploadFilesData(arrayMove(uploadFilesData, oldIndex, newIndex))
            setFileSortingChanged(true)
            setFilesChanged(true)
        }
    }

    function saveDocumentation(){
        if(foldersChanged){ saveFolders() }
        if(filesChanged){ saveFiles() }
    }

    return (
    <div className="box">
                
        {loading && <Loader />}

        <Prompt when={filesChanged || foldersChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col lg={4}>

                <Window title={t('folders')} actions={() => (
                    <div className="windowActions">
                        <Button variant="secondary" className="iconButton" onClick={addFolder}><img src={iconPlus} alt="icon" /></Button>
                        <Button variant="secondary" className={folderEditMode ? 'activeIconButton' : 'iconButton'} onClick={() => setFolderEditMode(!folderEditMode)}><img src={iconPen} alt="icon" /></Button>
                        <Button variant="success" disabled={!foldersChanged} onClick={saveDocumentation}>
                            { savingFolders ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>
                    
                    {activeFolder && <div className="folderBack" onClick={openParentFoder}>{ t('back') }</div>}
                    <SortableFolderList t={t} items={folders.filter(folder => folder.language === language)} folderEditMode={folderEditMode} activeFolder={activeFolder} setActiveFolder={setActiveFolder} changeFolder={changeFolder} deleteFolder={deleteFolder} onSortEnd={onFoldersSorted} useDragHandle={true} />


                </Window>

            </Col>
            <Col lg={8}>

                <Window title={t('files')} actions={() => (
                    <div className="windowActions">
                        <Button variant="secondary" className="iconButton" onClick={() => refFileInput.current.click()}><img src={iconPlus} alt="icon" /></Button>
                        <Button variant="secondary" className={fileEditMode ? 'activeIconButton' : 'iconButton'} onClick={() => setFileEditMode(!fileEditMode)}><img src={iconPen} alt="icon" /></Button>
                        <Button variant="success" disabled={!filesChanged} onClick={saveDocumentation}>
                            { savingFiles ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                        <input ref={refFileInput} type="file" multiple style={{display: 'none'}} onChange={addFiles} />
                    </div>
                )}>

                    <p style={{fontWeight: 700}}>{ activeFolderPath }</p>
                    
                    <SortableFileList t={t} items={files.filter(file => file.language === language)} activeFolder={activeFolder} fileEditMode={fileEditMode} changeFile={changeFile} deleteFile={deleteFile} onSortEnd={onFilesSorted} useDragHandle={true} />

                </Window>

            </Col>
        </Row>
    </div>
    )
}


const viewFile = async (uri) => {
    let file = await loadImage(uri)
    window.open(file, '_blank')
}

const loadImage = async (uri) => {
    let url
    let response = await axios.get(uri, {responseType: 'blob'})
    if(response.status === 200){
        url = URL.createObjectURL(response.data)
    }
    return Promise.resolve(url)
}

const SortableListHandle = SortableHandle(() => (
    <div className="listButton">
        <img src={iconMenu} alt="icon" />
    </div>
))



const SortableFolderList = SortableContainer(({ t, items, folderEditMode, activeFolder, setActiveFolder, changeFolder, deleteFolder }) => (
    <div className="folderList">
        {items.length < 1 && <div className="grey">{ t('noFolders') }</div>}
        {items.map((folder, index) => {
            if((activeFolder && parseInt(folder.parent_id) === parseInt(activeFolder.folder_id)) || (!activeFolder && folder.parent_id === null)){
                return !folderEditMode ? (
                    <SortableFolder key={'folder' + folder.folder_id} folder={folder} activeFolder={activeFolder} setActiveFolder={setActiveFolder} />
                ) : (
                    <SortableFolderEdit key={'folder' + folder.folder_id} index={index} folder={folder} changeFolder={changeFolder} deleteFolder={deleteFolder} />
                )
            }
        })}
    </div>
))

const SortableFolder = SortableElement(({ folder, activeFolder, setActiveFolder }) => (
    <Row className={activeFolder && parseInt(activeFolder.folder_id) === parseInt(folder.folder_id) ? 'folder active' : 'folder'} onClick={() => setActiveFolder(folder)}>
        <Col className="folderIcon">
            <img src={folder.fileCount > 0 ? iconFolder : iconFolderEmpty} alt="icon" />
        </Col>
        <Col>
            <div className="folderTitle">{folder.name}</div>
        </Col>
    </Row>
))

const SortableFolderEdit = SortableElement(({ folder, changeFolder, deleteFolder }) => (
    <Row style={{marginTop: 5, marginBottom: 5}}>
        <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
            <SortableListHandle />
        </Col>
        <Col>
            <Form.Control value={folder.name} onChange={e => changeFolder(folder.folder_id, e.target.value)} />
        </Col>
        <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
            <div className="listButton" onClick={() => deleteFolder(folder.folder_id)}>
                <img src={iconDelete} alt="icon" />
            </div>
        </Col>
    </Row>
))



const SortableFileList = SortableContainer(({ t, items, activeFolder, fileEditMode, changeFile, deleteFile }) => {
    return (
    <div className="fileList">
        {items.length < 1 && <div className="grey">{ t('noFiles') }</div>}
        {items.map((file, index) => {
            if((activeFolder && parseInt(file.folder_id) === parseInt(activeFolder.folder_id)) || (!activeFolder && !file.folder_id)){
                return !fileEditMode ? (
                    <SortableFile t={t} key={'file' + file.file_id} index={index} file={file} />
                ) : (
                    <SortableFileEdit key={'file' + file.file_id} index={index} file={file} changeFile={changeFile} deleteFile={deleteFile} />
                )
            }
        })}
    </div>
    )
})

const SortableFile = SortableElement(({ t, file }) => (
    <Row className="file">
        <Col className="fileIcon">
            <img src={getMimeIcon(file.mime)} alt="icon" />
        </Col>
        <Col>
            <div className="fileTitle">{file.name}</div>
            <div className="fileMeta">{file.file.split('.')[1]} • {filesize(file.size)} • { t('from') } {moment(file.created_at).format(t('dateFormat'))}</div>
        </Col>
        {file.id &&
        <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>            
            <div className="viewButton" onClick={() => viewFile(file.uri)}>
                <img src={iconEye} alt="icon" />
            </div>
        </Col>
        }
    </Row>
))

const SortableFileEdit = SortableElement(({ file, changeFile, deleteFile }) => (
    <Row style={{marginTop: 5, marginBottom: 5}}>
        <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
            <SortableListHandle />
        </Col>
        <Col>
            <Form.Control value={file.name} onChange={e => changeFile(file.file_id, e.target.value)} />
        </Col>
        <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
            <div className="listButton" onClick={() => deleteFile(file.file_id)}>
                <img src={iconDelete} alt="icon" />
            </div>
        </Col>
    </Row>
))


export default MachineDocumentationsScreen