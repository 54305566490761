import React, { createContext, useContext, useEffect, useState } from 'react'
import Placeholder from '../images/placeholder.jpg'
import flagDE from '../images/flags/de.svg'
import flagEN from '../images/flags/en.svg'

import Axios from 'axios'
import { axios } from './Axios'
import { LocalizationContext } from './LocalizationContext'

export const Context = createContext(null)

export default ({children}) => {

    const { t, locale } = useContext(LocalizationContext)

    const [loading, setLoading] = useState(true)
    const [appSettings, setAppSettings] = useState(null)

    const [language, setLanguage] = useState()
    const [currentFlag, setCurrentFlag] = useState()
    const [isDefaultLanguage, setIsDefaultLanguage] = useState()

    const [user, setUser] = useState(null)
    const [token, setToken] = useState(null)

    const [users, setUsers] = useState([])
    const [machines, setMachines] = useState([])
    const [customers, setCustomers] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [serviceText, setServiceText] = useState([])
    const [reminderMail, setReminderMail] = useState([])
    const [confirmationMail, setConfirmationMail] = useState([])
    const [machineFields, setMachineFields] = useState([])

    useEffect(() => {
        checkUser()
    }, [])

    async function checkUser(){
        let user = localStorage.getItem('user')
        let token = localStorage.getItem('token')
        if(user && token){
            setUser(JSON.parse(user))
            setToken(token)
        }else{
            setLoading(false)
        }
    }

    useEffect(() => {

        axios.get('app-settings')
        .then(response => {
            setAppSettings(JSON.parse(response.data.value))
        })

        if(user){

            Axios.all([
                axios.get('users'),
                axios.get('machines'),
                axios.get('customers'),
                axios.get('event-types'),
                axios.get('servicetext'),
                axios.get('app-settings'),
                axios.get('machine-fields'),
                axios.get('mail/eventReminderMail'),
                axios.get('mail/eventConfirmationMail'),
            ])
            .then(Axios.spread((...response) => {
                let responseUsers = response[0]
                if(responseUsers.status === 200){
                    setUsers(responseUsers.data)
                }
                let responseMachines = response[1]
                if(responseMachines.status === 200){
                    setMachines(responseMachines.data)
                    prepareMachines(responseMachines.data)
                }
                let responseCustomers = response[2]
                if(responseCustomers.status === 200){
                    setCustomers(responseCustomers.data)
                }
                let responseEventTypes = response[3]
                if(responseEventTypes.status === 200){
                    setEventTypes(responseEventTypes.data)
                }
                let responseServiceText = response[4]
                if(responseServiceText.status === 200){
                    setServiceText(responseServiceText.data)
                }
                let responseAppSettings = response[5]
                if(responseAppSettings.status === 200){
                    setAppSettings(JSON.parse(responseAppSettings.data.value))
                }
                let responseMachineFields = response[6]
                if(responseMachineFields.status === 200){
                    setMachineFields(responseMachineFields.data)
                }
                let responseReminderMail = response[7]
                if(responseReminderMail.status === 200){
                    if(responseReminderMail.data)
                        setReminderMail(responseReminderMail.data)
                    else
                        setReminderMail({ value: { html: '', subject: '' }, translations: null })
                }
                let responseConfirmationMail = response[8]
                if(responseConfirmationMail.status === 200){
                    if(responseConfirmationMail.data)
                        setConfirmationMail(responseConfirmationMail.data)
                    else
                        setConfirmationMail({ value: { html: '', subject: '' }, translations: null })
                }
                setLoading(false)
            }))
            .catch(err => console.log(err))

        }

    }, [user])

    useEffect(() => {
        machines.sort(function (a, b){
            var textA = a.name.toLowerCase();
            var textB = b.name.toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
    }, [machines])

    useEffect(() => {
        customers.sort(function (a, b){
            var textA = a.company.toLowerCase();
            var textB = b.company.toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
    }, [customers])

    async function prepareMachines(machinesResponse){
        let m = await loadMachineImages(machinesResponse)
        setMachines(m)
    }

    function loadMachineImages(machinesResponse){
        return Promise.all(machinesResponse.map(async (m) => {
            let image = await loadImage(m.image_uri)
            return {
                ...m,
                image_cache: image,
            }
        }))
    }

    async function loadImage(uri){
        let url = Placeholder
        if(uri){
            let response = await axios.get(uri, {responseType: 'blob'})
            if(response.status === 200){
                url = URL.createObjectURL(response.data)
            }
        }
        return Promise.resolve(url)
    }

    
    const changeLanguage = (lang) => {

        setLanguage(lang)

        setIsDefaultLanguage(locale === lang)

        switch (lang){
            case 'de':
                setCurrentFlag(flagDE)
                return
            case 'en':
                setCurrentFlag(flagEN)
                return
        }
    }


    function checkFields(array, fields){
        let success = true
        array.map(e => {
            fields.map(field => {
                if(!e[field]){ success = false }
            })
        })
        if(!success){ alert(t('alertFillFields')) }
        return success
    }


    async function logout(){
        let response = await axios.post('logout')
        if(response.status === 200){
            localStorage.clear()
            setUser(null)
            setToken(null)
        }
    }

    async function updateViewMode(mode){
        let updatedUser = {
            ...user,
            viewmode: mode,
        }
        setUser(updatedUser)
        localStorage.setItem('user', JSON.stringify(updatedUser))
    }


    const data = {
        loading, setLoading,
        appSettings, setAppSettings,
        language, changeLanguage, currentFlag, isDefaultLanguage,
        eventTypes, setEventTypes,
        user, setUser, token, setToken,
        users, setUsers,
        machines, setMachines,
        machineFields, setMachineFields,
        customers, setCustomers,
        serviceText, setServiceText,
        reminderMail, setReminderMail,
        confirmationMail, setConfirmationMail,
        logout, loadImage, checkFields, updateViewMode,
    }

    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
    
}