import React, { useState, useEffect, useContext } from 'react'
import { Form, Table } from 'react-bootstrap'
import moment from 'moment'

import { axios } from '../scripts/Axios'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import PageHeader from '../components/PageHeader'
import Loader from '../components/Loader'

function HistoryScreen() {

    const { users } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [loading, setLoading] = useState(false)
    const [history, setHistory] = useState([])
    const [filteredHistory, setFilteredHistory] = useState([])
    const [filter, setFilter] = useState('')

    useEffect(() => {
        setLoading(true)
        getHistory()
    }, [])

    useEffect(() => {
        setFilteredHistory(history.filter(h => {
            return filter ? parseInt(h.user_id) === parseInt(filter) : true
        }))
    }, [history, filter])

    async function getHistory(){        
        let response = await axios.get('history')
        if(response.status === 200){
            setHistory(response.data)
            setLoading(false)
        }
    }

    return (
    <div>
        
        <PageHeader title={ t('menuHistory') } />
        
        <div id="pageActions">
            <div className="container" style={{justifyContent: 'flex-start'}}>
                <Form.Control as="select" size="lg" style={{maxWidth: 300, marginRight: 15}} onChange={e => setFilter(e.target.value)}>
                    <option value="">{ t('allUsers') }</option>
                    <option value={0}>{ t('system') }</option>
                    {users.map((user, index) => <option key={'userOption' + index} value={user.id}>{user.name}</option>)}
                </Form.Control>
            </div>
        </div>

        <div id="pageContent">
            <div className="container">

                {loading && <Loader />}

                <div className="box">
                    <Table responsive className="historyTable">
                        <thead>
                            <tr>
                                <th style={{minWidth:140}}>{ t('date') }</th>
                                <th style={{minWidth:200}}>{ t('user') }</th>
                                <th>{ t('activity') }</th>
                            </tr>
                        </thead>
                        <tbody>
                        {filteredHistory && filteredHistory.map((h, index) => {
                            let user = users.find(u => u.id === parseInt(h.user_id))
                            return (
                            <tr key={'historyItem' + index}>
                                <td className="grey" width={180}>{moment(h.created_at).format(t('dateFormat') + ' HH:mm')}</td>
                                <td width={260}>{h.user_id === 0 ? 'System' : (user ? user.name : '[' + t('userDeleted') + ']')}</td>
                                <td dangerouslySetInnerHTML={{__html: h.desc}}></td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

            </div>
        </div>

    </div>
    )
}

export default HistoryScreen