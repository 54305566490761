import React, { useEffect, useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Button, Form, Alert } from 'react-bootstrap'
import iconLoading from '../../images/loader-button.svg'
import iconArrowRight from '../../images/arrow-right.svg'

import Window from '../../components/Window'
import MachineGridItem from '../../components/MachineGridItem'
import { axios } from '../../scripts/Axios'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function MachineTransferScreen(){

    const { machines, setMachines, customers, appSettings } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const { id } = useParams()
    const history = useHistory()

    const [machine, setMachine] = useState()
    const [customer, setCustomer] = useState()
    const [targetCustomer, setTargetCustomer] = useState()
    const [targetAddress, setTargetAddress] = useState('')
    const [savingTransfer, setSavingTransfer] = useState(false)
    
    useEffect(() => {
        if(machines){
            setMachine(machines.find(m => m.id === parseInt(id)))
        }
    }, [machines])

    useEffect(() => {
        if(machine && machine.customer_id){
            setCustomer(customers.find(c => c.id === machine.customer_id))
        }
    }, [machine])

    function changeTarget(e){
        let id = parseInt(e.target.value)
        let c = { id: 0, address: '' }
        if(id !== 0){
            c = customers.find(c => c.id === id)
        }
        if(c){
            setTargetCustomer(c)
            setTargetAddress(c.address)
        }else{
            setTargetCustomer(null)
            setTargetAddress('')
        }
    }

    async function transferMachine(){
        setSavingTransfer(true)
        let response = await axios.put('machine/' + machine.id + '/transfer', {
            target: targetCustomer.id,
            location: targetAddress,
        })
        if(response.status === 200){
            setSavingTransfer(false)
            setMachines(machines.map(m => {
                return m.id === machine.id ? {
                    ...m,
                    customer_id: response.data.customer_id,
                    location: response.data.location,
                    secret: response.data.secret,
                } : m
            }))
            // generate print pdf here ...
            history.push('/machines/' + machine.id)
        }
    }

    return (
    <Row>
        <Col lg={3}>
            {machine && <MachineGridItem machine={machine} />}
        </Col>
        <Col lg={9}>
            <div className="box">
            
                { customer &&
                <Alert variant="warning">{ t('transferWarning') }</Alert>
                }

                <Window title={ t('transferMachine') } actions={() => (
                    <div className="windowActions">
                        <Button variant="success" disabled={!targetCustomer} onClick={transferMachine}>
                            { savingTransfer ? <img src={iconLoading} alt="icon" /> : t('transfer') }
                        </Button>
                    </div>
                )}>

                    { customer ? (
                    <Row>
                        <Col>
                            <Form.Label>{ t('by') }:</Form.Label>
                            <div>{customer && customer.company}</div>
                            <div style={{whiteSpace: 'break-spaces'}}>{customer && customer.address}</div>
                        </Col>
                        <Col style={{flexBasis: 0, flexGrow: 0}}>
                            <img src={iconArrowRight} width={40} height={40} alt="icon" />
                        </Col>
                        <Col>
                            <Form.Control as="select" value={targetCustomer && targetCustomer.id} onChange={changeTarget}>
                                <option>{ t('chooseDestination') + ' ...' }</option>
                                <option value={0}>{ appSettings.companyName }</option>
                                {customers && customers.filter(c => c.id !== customer.id).map(c => <option key={'option' + c.id} value={c.id}>{c.company}</option>)}
                            </Form.Control>

                            {targetCustomer &&
                            <div style={{marginTop: 10}}>                                
                                <Form.Label>{ t('location') }:</Form.Label>
                                <Form.Control as="textarea" rows={3} value={targetAddress} onChange={e => setTargetAddress(e.target.value)} />
                            </div>
                            }
                        </Col>
                    </Row>
                    ) : (
                    <Row>
                        <Col style={{flexBasis: 0, flexGrow: 0}}>
                            <img src={iconArrowRight} width={40} height={40} alt="icon" />
                        </Col>
                        <Col>
                            <Form.Control as="select" value={targetCustomer && targetCustomer.id} onChange={changeTarget}>
                                <option>{ t('chooseDestination') + ' ...' }</option>
                                {customers && customers.map(c => <option key={'option' + c.id} value={c.id}>{c.company}</option>)}
                            </Form.Control>

                            {targetCustomer &&
                            <div style={{marginTop: 10}}>                                
                                <Form.Label>{ t('location') }:</Form.Label>
                                <Form.Control as="textarea" rows={3} value={targetAddress} onChange={e => setTargetAddress(e.target.value)} />
                            </div>
                            }
                        </Col>
                    </Row>
                    )}

                </Window>

            </div>
        </Col>
    </Row>
    )
}

export default MachineTransferScreen