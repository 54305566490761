import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import iconPlus from '../../images/plus.svg'
import iconDelete from '../../images/delete.svg'
import iconLoading from '../../images/loader-button.svg'

import Window from '../../components/Window'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { axios } from '../../scripts/Axios'

function SettingsUserScreen() {

    const { users, setUsers, checkFields } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [currentUsers, setCurrentUsers] = useState([])
    const [deleteUsers, setDeleteUsers] = useState([])
    const [usersChanged, setUsersChanged] = useState(false)
    const [savingUsers, setSavingUsers] = useState(false)

    useEffect(() => {
        setCurrentUsers(users)
    }, [users])

    function addUser(){
        setCurrentUsers([
            {
                name: '',
                email: '',
                password: '',
                role: 'staff',
            },
            ...currentUsers,
        ])
        setUsersChanged(true)
    }

    function changeUser(i, field, value){
        let updatedUsers = currentUsers.map((user, index) => {
            return i === index ? {
                ...user,
                [field]: value,
            } : user
        })
        setCurrentUsers(updatedUsers)
        setUsersChanged(true)
    }

    function deleteUser(i){
        let del = currentUsers.find((user, index) => index === i)
        if(del.id){
            setDeleteUsers([...deleteUsers, del])
        }        
        setCurrentUsers(currentUsers.filter((user, index) => index !== i))
        setUsersChanged(true)
    }

    async function saveUsers(){
        let check = checkFields(currentUsers, ['name', 'email', 'role'])
        if(!check){ return }
        setSavingUsers(true)
        let response = await axios.put('users', {
            updateUsers: currentUsers.filter(user => user.id),
            createUsers: currentUsers.filter(user => !user.id),
            deleteUsers: deleteUsers,
        })
        if(response.status === 200){
            setUsers(response.data)
            setDeleteUsers([])
            setSavingUsers(false)
            setUsersChanged(false)
        }
    }


    return (
    <div className="box">

        <Prompt when={usersChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col>

                <Window title={ t('users') } actions={() => (
                    <div className="windowActions">
                        <Button variant="secondary" className="iconButton" onClick={addUser}><img src={iconPlus} alt="icon" /></Button>
                        <Button variant="success" disabled={!usersChanged} onClick={saveUsers}>
                            { savingUsers ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>
                    
                    <div className="itemList">
                        {currentUsers && currentUsers.map((user, index) => (                                
                            <Row key={'userItem' + index}>
                                <Col>
                                    <Form.Control value={user.name} placeholder={ t('firstAndLastname') } onChange={e => changeUser(index, 'name', e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Control value={user.email} placeholder={ t('email') } onChange={e => changeUser(index, 'email', e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Control type="password" autoComplete="new-password" placeholder={user.password !== '' ? '••••••••••••' : t('password')} onChange={e => changeUser(index, 'password', e.target.value)} />
                                </Col>
                                <Col>                                
                                    <Form.Control as="select" value={user.role} disabled={user.id === 1} onChange={e => changeUser(index, 'role', e.target.value)}>
                                        <option value="staff">{ t('roleStaff') }</option>
                                        <option value="admin">{ t('roleAdmin') }</option>
                                    </Form.Control>
                                </Col>
                                <Col style={{flexBasis:40, flexGrow:0, alignSelf:'center'}}>
                                    { user.id !== 1 &&
                                    <div className="listButton" onClick={() => deleteUser(index)}>
                                        <img src={iconDelete} alt="icon" />
                                    </div>
                                    }
                                </Col>
                            </Row>
                        ))}
                    </div>

                </Window>

            </Col>
        </Row>
    </div>
    )
}

export default SettingsUserScreen