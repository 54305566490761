import React, { useEffect, useState, useContext } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import moment from 'moment'

import PageHeader from '../components/PageHeader'
import Timeline from '../components/Timeline'
import Loader from '../components/Loader'
import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { axios } from '../scripts/Axios'

function EventsScreen() {

    const { machines, customers, eventTypes } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    const [loading, setLoading] = useState(false)
    const [rawEvents, setRawEvents] = useState([])
    const [events, setEvents] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])

    const [filter, setFilter] = useState({
        customer: '',
        eventType: '',
    })

    const [timelineEvents, setTimelineEvents] = useState([])
    const [latestDate, setLatestDate] = useState(moment().add(6, 'months'))

    useEffect(() => {
        setLoading(true)
        getEvents()
    }, [])

    useEffect(() => {
        filterEvents()
    }, [filter, events])


    useEffect(() => {

        let sortedEvents = filteredEvents
        sortedEvents.sort(sortByDates)
        let groupedEvents = sortedEvents.reduce((r, a) => {
            r[a.date] = r[a.date] || []
            r[a.date].push(a)
            return r;
        }, Object.create(null))
        setTimelineEvents(groupedEvents)

    }, [filteredEvents])


    function filterEvents(){
        if(filter.eventType || filter.customer){
            let filteredEvents = events
            if(filter.eventType){
                filteredEvents = filteredEvents.filter(e => parseInt(e.event_type_id) === parseInt(filter.eventType))
            }
            if(filter.customer){
                filteredEvents = filteredEvents.filter(e => {
                    let machine = machines.find(m => parseInt(m.id) === parseInt(e.machine_id))
                    return parseInt(machine.customer_id) === parseInt(filter.customer) ? true : false
                })
            }
            setFilteredEvents(filteredEvents)
        }else{
            setFilteredEvents(events)
        }
    }

    function changeLatestDate(){
        let newLatestDate = latestDate.add(12, 'months')
        setLatestDate(newLatestDate)
        generateEvents(rawEvents)
    }
    
    function sortByDates(a, b){
        if(a.date < b.date){ return -1 }
        if(a.date > b.date){ return 1 }
        return 0
    }

    async function getEvents(){        
        let response = await axios.get('events')
        if(response.status === 200){

            setRawEvents(response.data)
            generateEvents(response.data)

        }
    }

    function generateEvents(rawEvents){
        
        let newEvents = []
        let today = moment()

        rawEvents.map(e => {

            let initDate = moment(e.initial_date)

            if(initDate.isSame(today, 'day')){
                newEvents.push({
                    ...e,
                    date: initDate.format('YYYY-MM-DD'),
                })
            }
            else if(initDate.isAfter(today) && initDate.isBefore(latestDate)){
                newEvents.push({
                    ...e,
                    date: initDate.format('YYYY-MM-DD'),
                })
            }

            if(e.repeat !== 'none'){
                let futureEvents = generateFutureEvents(e, latestDate)
                newEvents = [...newEvents, ...futureEvents]
            }

        })

        setEvents(newEvents)
        setLoading(false)

    }

    function generateFutureEvents(e, until){

        let events = []
        let today = moment()
        let shouldRepeat = true
        let currentDate = moment(e.initial_date)

        while(shouldRepeat){
            let addDate = currentDate.add(1, e.repeat)

            // Moment Month Add Fix
            if(e.repeat == 'month'){
                let endOfMonth = addDate.endOf('month')
                addDate = addDate.isSame(endOfMonth.format('YYYY-MM-DD')) ? addDate.add(1, 'day') : addDate
            }

            if(addDate.isBefore(until)){

                if(addDate.isSame(today, 'day') || addDate.isAfter(today)){
                    events.push({
                        ...e,
                        date: addDate.format('YYYY-MM-DD'),
                    })
                    currentDate = addDate
                }

            }else{
                shouldRepeat = false
            }
        }

        return events

    }

    return (
    <div>
        
        <PageHeader title={ t('menuEvents') } />

        <div id="pageActions" style={{paddingBottom:30}}>
            <div className="container">
                <Form.Control as="select" size="lg" style={{maxWidth: 300, marginRight: 15}} onChange={e => setFilter({...filter, customer: e.target.value})}>
                    <option value="">{ t('allCustomers') }</option>
                    {customers.map((customer, index) => <option key={'customer' + index} value={customer.id}>{customer.company}</option>)}
                </Form.Control>
                <Form.Control as="select" size="lg" style={{maxWidth: 300}} onChange={e => setFilter({...filter, eventType: e.target.value})}>
                    <option value="">{ t('allEvents') }</option>
                    {eventTypes.map((type, index) => <option key={'eventType' + index} value={type.id}>{type.name}</option>)}
                </Form.Control>
            </div>
        </div>

        <div id="pageContent" style={{marginTop:0}}>
            <div className="container">
                
                {loading && <Loader />}

                <Timeline events={timelineEvents} />

                <Row>
                    <Col lg={{span: 10, offset: 2}}>
                        <Button onClick={changeLatestDate} style={{transform: 'translateX(-50%)', marginLeft: -5}}>{ t('showMore') }</Button>
                    </Col>
                </Row>

            </div>
        </div>

    </div>
    )
}

export default EventsScreen