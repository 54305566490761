import React, { useEffect, useContext, useState } from 'react'
import { useParams, Prompt } from 'react-router-dom'
import { Row, Col, Button, InputGroup, Form } from 'react-bootstrap'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import iconPlus from '../../images/plus.svg'
import iconDelete from '../../images/delete.svg'
import iconLoading from '../../images/loader-button.svg'
import iconMenu from '../../images/menu.svg'

import Window from '../../components/Window'

import { axios } from '../../scripts/Axios'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import Loader from '../../components/Loader'

function MachineEventsScreen() {

    const { eventTypes, checkFields } = useContext(Context)
    const { t, locale } = useContext(LocalizationContext)
    const { id } = useParams()

    const [loading, setLoading] = useState(true)

    const [events, setEvents] = useState([])
    const [eventsChanged, setEventsChanged] = useState(false)
    const [savingEvents, setSavingEvents] = useState(false)
    const [deleteEvents, setDeleteEvents] = useState([])
    const [sortingChanged, setSortingChanged] = useState(false)

    useEffect(() => {
        getEvents()
    }, [])

    useEffect(() => {
        if(sortingChanged === true){
            setEvents(events.map((event, index) => ({...event, sortkey: index})))
            setSortingChanged(false)
        }
    }, [sortingChanged])

    async function getEvents(){
        let response = await axios.get('machine/' + id + '/events')
        if(response.status === 200){
            setEvents(response.data)
            setLoading(false)
        }
    }

    function addEvent(){
        setEvents([
            {
                machine_id: id,
                event_type_id: null,
                initial_date: null,
                repeat: 'none',
                remember: null,
            },
            ...events,
        ])
        setEventsChanged(true)
    }

    function changeEvent(index, field, value){
        let updatedEvents = events.map((event, eventIndex) => {
            if(index !== eventIndex){
                return event
            }else{
                let newEvent = {
                    ...event,
                    [field]: value,
                }
                if(field === 'event_type_id'){
                    let evtType = eventTypes.find(type => type.id === parseInt(value))
                    newEvent.remember = evtType ? evtType.remember : newEvent.remember
                }
                return newEvent
            }
        })
        setEvents(updatedEvents)
        setEventsChanged(true)
    }

    function deleteEvent(i){
        let del = events.find((event, index) => index === i)
        if(del.id){
            setDeleteEvents([...deleteEvents, del])
        }        
        setEvents(events.filter((event, index) => index !== i))
        setEventsChanged(true)
    }

    async function saveEvents(){
        let check = checkFields(events, ['event_type_id', 'initial_date'])
        if(!check){ return }
        setSavingEvents(true)
        let response = await axios.put('machine/' + id + '/events', {
            updateEvents: events.filter(event => event.id),
            createEvents: events.filter(event => !event.id),
            deleteEvents: deleteEvents,
        })
        if(response.status === 200){
            setEvents(response.data)
            setDeleteEvents([])
            setSavingEvents(false)
            setEventsChanged(false)
        }
    }

    function onEventsSorted({oldIndex, newIndex}) {
        if(oldIndex !== newIndex){
            setEvents(arrayMove(events, oldIndex, newIndex))
            setSortingChanged(true)
            setEventsChanged(true)
        }
    }

    return (
    <div className="box">
                
        {loading && <Loader />}

        <Prompt when={eventsChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col>

                <Window title="Termine" actions={() => (
                    <div className="windowActions">
                        <Button variant="secondary" className="iconButton" onClick={addEvent}><img src={iconPlus} alt="icon" /></Button>
                        <Button variant="success" disabled={!eventsChanged} onClick={saveEvents}>
                            { savingEvents ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>
                    
                    <SortableEventList t={t} locale={locale} events={events} changeEvent={changeEvent} deleteEvent={deleteEvent} eventTypes={eventTypes} onSortEnd={onEventsSorted} useDragHandle={true} />

                </Window>

            </Col>
        </Row>
    </div>
    )
}

const SortableListHandle = SortableHandle(() => (
    <div className="listButton">
        <img src={iconMenu} alt="icon" />
    </div>
))

const SortableEventList = SortableContainer(({ t, locale, events, changeEvent, deleteEvent, eventTypes }) => (
    <div className="itemList">
        {events.length < 1 && <div className="grey">{ t('noEvents') }</div>}
        {events && events.map((event, index) => (
            <SortableEvent t={t} locale={locale} key={'eventItem' + index} index={index} i={index} event={event} changeEvent={changeEvent} deleteEvent={deleteEvent} eventTypes={eventTypes} />
        ))}
    </div>
))

const SortableEvent = SortableElement(({ t, locale, i, event, changeEvent, deleteEvent, eventTypes }) => (                                    
    <Row>
        <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
            <SortableListHandle />
        </Col>
        <Col>
            <Form.Control as="select" value={event.event_type_id || ''} onChange={e => changeEvent(i, 'event_type_id', e.target.value)}>
                <option>{ t('chooseEventType') + ' ...' }</option>
                {eventTypes.map(type => <option key={'eventType' + type.id} value={type.id}>{ locale in type.translations ? type.translations[locale] : type.name }</option>)}
            </Form.Control>
        </Col>
        <Col lg={2}>
            <Form.Control type="date" value={event.initial_date || ''} onChange={e => changeEvent(i, 'initial_date', e.target.value)} />
        </Col>
        <Col>
            <Form.Control as="select" value={event.repeat} onChange={e => changeEvent(i, 'repeat', e.target.value)}>
                <option value={'none'}>{ t('noRepeat') }</option>
                <option value={'day'}>{ t('dailyRepeat') }</option>
                <option value={'week'}>{ t('weeklyRepeat') }</option>
                <option value={'month'}>{ t('monthlyRepeat') }</option>
                <option value={'year'}>{ t('yearlyRepeat') }</option>
            </Form.Control>
        </Col>
        <Col>
            <InputGroup>
                <Form.Control type="number" value={event.remember || ''} step="1" min="0" onChange={e => changeEvent(i, 'remember', e.target.value)} />
                <InputGroup.Append>
                    <InputGroup.Text>{ t('remindDaysBefore') }</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        </Col>
        <Col style={{flexBasis:0, flexGrow:0, alignSelf:'center'}}>
            <div className="listButton" onClick={() => deleteEvent(i)}>
                <img src={iconDelete} alt="icon" />
            </div>
        </Col>
    </Row>
))

export default MachineEventsScreen