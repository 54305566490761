import React, { useContext } from 'react'
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import flagDE from '../images/flags/de.svg'
import flagEN from '../images/flags/en.svg'

import { Context } from '../scripts/Context'
import { LocalizationContext } from '../scripts/LocalizationContext'

function LanguageSelector({ offsetLeft = 20 }) {

    const { changeLanguage, currentFlag } = useContext(Context)
    const { t } = useContext(LocalizationContext)

    return (                 
        <DropdownButton as={ButtonGroup} size="lg" title={<img src={currentFlag} alt="flag" />} className="languageSelector" style={{ marginLeft: offsetLeft }}>
            <Dropdown.Item onClick={() => changeLanguage('de')}>
                <img src={flagDE} alt="flag" /> { t('german') }
            </Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('en')}>
                <img src={flagEN} alt="flag" /> { t('english') }
            </Dropdown.Item>
        </DropdownButton>
    )
}

export default LanguageSelector