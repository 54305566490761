import React, { useState, useRef, useEffect, useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import { ColorPicker, useColor, toColor } from 'react-color-palette'
import 'react-color-palette/lib/css/styles.css'
import iconLoading from '../../images/loader-button.svg'
import packageJson from '../../../package.json'

import Window from '../../components/Window'
import { Context } from '../../scripts/Context'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { axios } from '../../scripts/Axios'

function SettingsAppScreen() {

    const { appSettings, setAppSettings } = useContext(Context)
    const { t, locale, setLocale } = useContext(LocalizationContext)

    const [settings, setSettings] = useState([])
    const [settingsChanged, setSettingsChanged] = useState(false)
    const [savingSettings, setSavingSettings] = useState(false)

    const refFileInput = useRef()
    
    const [companyName, setCompanyName] = useState('QR Tool')
    const [language, setLanguage] = useState(locale)
    const [primaryColor, setPrimaryColor] = useColor('hex', '#000')
    const [primaryColorDark, setPrimaryColorDark] = useColor('hex', '#000')

    useEffect(() => {
        setSettings(appSettings)
        setCompanyName(appSettings.companyName)
        setPrimaryColor(toColor('hex', appSettings.appColorPrimary))
        setPrimaryColorDark(toColor('hex', appSettings.appColorPrimaryDark))
    }, [appSettings])

    async function changeImage(e){        
        if(e.target.files.length){
            
            let formData = new FormData()
            formData.append('image', e.target.files[0])

            let response = await axios.post('logo', formData)
            if(response.status === 200){
                setSettings({
                    ...settings,
                    appLogo: response.data,
                })
                setSettingsChanged(true)
            }

        }
    }

    function changePrimaryColor(c){
        setPrimaryColor(c)
        changeSettings('appColorPrimary', c.hex)
    }
    function changePrimaryColorDark(c){
        setPrimaryColorDark(c)
        changeSettings('appColorPrimaryDark', c.hex)
    }
    function changeCompanyName(n){
        setCompanyName(n)
        changeSettings('companyName', n)
    }
    function changeLanguage(l){
        setLanguage(l)
        changeSettings('language', l)
    }
    
    function changeSettings(field, value){
        setSettings({
            ...settings,
            [field]: value,
        })
        setSettingsChanged(true)
    }

    async function saveSettings(){
        setSavingSettings(true)
        let response = await axios.put('app-settings', {settings: settings})
        if(response.status === 200){
            setAppSettings(settings)
            setSavingSettings(false)
            setSettingsChanged(false)
            setLocale(settings.language)
        }
    }

    return (
    <div className="box">

        <Prompt when={settingsChanged} message={ t('alertUnsavedChanges') } />

        <Row>
            <Col>

                <Window title={ t('appSettings') } actions={() => (
                    <div className="windowActions">
                        <Button variant="success" disabled={!settingsChanged} onClick={saveSettings}>
                            { savingSettings ? <img src={iconLoading} alt="icon" /> : t('save') }
                        </Button>
                    </div>
                )}>
                    
                    { settings &&
                    <div className="inputList">

                        <Row>
                            <Col lg={12} xl={4}>

                                <div className="mb-4">
                                    <Form.Label column>{ t('logo') }</Form.Label>
                                    <img src={settings.appLogo} alt="image" style={{maxWidth: '100%', maxHeight: 200, marginBottom: 10}} />
                                    <Button variant="secondary" onClick={() => refFileInput.current.click()}>{ t('changeLogo') }</Button>
                                    <input ref={refFileInput} type="file" style={{display: 'none'}} onChange={changeImage} />
                                </div>

                                <div className="mb-2">
                                    <Form.Label column sm={4}>{ t('company') }</Form.Label>
                                    <Form.Control value={companyName} onChange={e => changeCompanyName(e.target.value)} />
                                </div>

                                <div className="mb-4">
                                    <Form.Label column className="mt-3">{ t('appLanguage') }</Form.Label>
                                    <Form.Control as="select" size="lg" value={language} onChange={e => changeLanguage(e.target.value)}>
                                        <option value="de">{ t('german') }</option>
                                        <option value="en">{ t('english') }</option>
                                    </Form.Control>
                                </div>

                                <div>
                                    <small>Version { packageJson.version }</small>
                                </div>

                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="pl-xl-5 mt-sm-4 mt-xl-0">
                                    <Form.Label column>{ t('primaryAppColor') }</Form.Label>
                                    <ColorPicker width={300} height={150} color={primaryColor} onChange={changePrimaryColor} hideHSV hideRGB dark />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mt-sm-4 mt-xl-0">
                                    <Form.Label column>{ t('secondaryAppColor') }</Form.Label>
                                    <ColorPicker width={300} height={150} color={primaryColorDark} onChange={changePrimaryColorDark} hideHSV hideRGB dark />
                                </div>
                            </Col>
                        </Row>

                    </div>
                    }

                </Window>

            </Col>
        </Row>

    </div>
    )
}

export default SettingsAppScreen